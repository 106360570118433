const timeUtils = {
  install(app) {
    app.config.globalProperties.$getTimeDifference = function (startTime) {
      const now = new Date().getTime();
      const difference = now - startTime;

      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      const result = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

      return result;
    }
    app.config.globalProperties.$timeEntryDuration = function (entry) {
      const startDate =
        (typeof entry.start === "string" || entry.start instanceof String)
          ? new Date(entry.start)
          : entry.start;
      const endDate =
        (typeof entry.end === "string" || entry.end instanceof String)
          ? new Date(entry.end)
          : entry.end;
      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
      const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
      const minutes = Math.floor(
        (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
      );
      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");
      return `${formattedHours}:${formattedMinutes}`;
    };
    app.config.globalProperties.$convertTimeFloat = function (timeFloat) {
      const hours = Math.floor(timeFloat);
      const fractionalHours = timeFloat - hours;
      const minutes = Math.round(fractionalHours * 60);
      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}`;
    };
    app.config.globalProperties.$convertSeconds = function (seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };
    app.config.globalProperties.$parseTimeEntryDescription = function(str, maxLength = 40){
        const taskIdMatch = str.match(/( \| | \| | )?(#[a-zA-Z0-9]+)$/);

        if (taskIdMatch) {
            const taskId = taskIdMatch[2];
            const fullMatch = taskIdMatch[0];
            if (str.length - fullMatch.length + taskId.length > maxLength - 3) {
                const substrLength = maxLength - taskId.length - 3;
                return `<span>${str.substr(0, substrLength)}... <span class="list-task-id">${taskId}</span></span>`;
            } else {
                return `<span>${str.substr(0, str.length - fullMatch.length)} <span class="list-task-id">${taskId}</span></span>`;
            }
        } else if (str.length > maxLength) {
            return `<span>${str.substr(0, maxLength - 3)}...</span>`;
        }

        return `<span>${str}</span>`;
    }
  },
};

export default timeUtils;
