<template>
  <div class="container p-4">

    <div class="w-full">
      <label class="relative inline-flex items-center cursor-pointer mt-4">
        <input type="checkbox" v-model="compactView" name="compactView" class="sr-only peer">
        <div
          class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
        </div>
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Compact View</span>
      </label>
    </div>
    <div class="w-full">
      <label class="relative inline-flex items-center cursor-pointer mt-4">
        <input type="checkbox" v-model="autoExpand" name="autoExpand" class="sr-only peer">
        <div
            class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
        </div>
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Auto-expand Days</span>
      </label>
    </div>

    <div id="pomodoroSection">
      <div class="w-full">
        <label class="relative inline-flex items-center cursor-pointer mt-4">
          <input type="checkbox" v-model="pomodoroMode" name="pomodoroMode" class="sr-only peer">
          <div
            class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
          </div>
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Pomodoro Mode</span>
        </label>
      </div>
      <div class="w-full pt-4 text-white" v-if="pomodoroMode">
        <label>Pomodoro Segment Length</label>
        <input type="number" v-model="pomodoroWorkLength" placeholder="Enter minutes..."
          class="input-timeeditor text-white p-2 mt-2 mb-2 border rounded" />
        <br />
        <label>Pomodoro Short Break Length</label>
        <input type="number" v-model="pomodoroShortBreakLength" placeholder="Enter minutes..."
          class="input-timeeditor p-2 mt-2 mb-2 border rounded" />
      </div>
    </div>

<!--
    <div id="reminderSection">
      <div class="w-full">
        <label class="relative inline-flex items-center cursor-pointer mt-4">
          <input type="checkbox" v-model="reminder" name="reminder" class="sr-only peer">
          <div
            class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
          </div>
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Inactivity Reminder</span>
        </label>
      </div>

      <div class="w-full pt-4 text-white" v-if="reminder">
        <label>First reminder should arrive after...</label>
        <input type="number" v-model="reminderDelay" placeholder="Enter minutes..."
          class="form-input p-2 mt-2 mb-2 border rounded w-full options-input" />
        <label>Consecutive reminders should arrive every...</label>
        <input type="number" v-model="reminderInterval" placeholder="Enter minutes..."
          class="form-input p-2 mt-2 mb-2 border rounded w-full options-input" />
      </div>
    </div>


    <div class="w-full">
      <label class="relative inline-flex items-center cursor-pointer mt-4">
        <input type="checkbox" v-model="projectDetection" name="projectDetection" class="sr-only peer">
        <div
          class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
        </div>
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Attempt to recognize project from ClickUp
          task</span>
      </label>
    </div>
    -->
    <div class="w-full">
      <label class="relative inline-flex items-center cursor-pointer mt-4">
        <input type="checkbox" v-model="clientLimitWarning" name="clientLimitWarning" class="sr-only peer">
        <div
          class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
        </div>
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Notify me about client's time limit</span>
      </label>
    </div>
    <div class="w-full">
      <label class="relative inline-flex items-center cursor-pointer mt-4">
        Suggestions Amount
      </label>
        <input type="number" min="1" max="25" v-model="suggestionsAmount" name="suggestionsAmount" class="input-timeeditor text-white p-2 mt-2 mb-2 border rounded w-full" >

    </div>
    <div class="w-full">
      <button type="button" @click="saveOptions()" class="button-task-tracker bordered mt-2" :class="{
        'cursor-not-allowed': loading
      }" :disabled="loading">Save</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExtensionOptions",
  data() {
    return {
      loading: false,
      compactView: false,
      pomodoroMode: false,
      autoExpand: false,
      suggestionsAmount: 10,
      pomodoroWorkLength: 25,
      pomodoroShortBreakLength: 5,
      reminder: false,
      reminderDelay: 10,
      reminderInterval: 15,
      projectDetection: false,
      clientLimitWarning: false,
    };
  },
  mounted() {
    this.loadOptions();
  },
  computed: {
  },
  methods: {
    async loadOptions() {
      try {
        const response = await this.$makeRequest(
          "get",
          "/_user/api/v2/fetch"
        );
        if (response.data && response.data.timetracker_options) {
          const options = response.data.timetracker_options;
          this.pomodoroMode = options.pomodoroMode
          this.pomodoroWorkLength = options.pomodoroWorkLength
          this.pomodoroShortBreakLength = options.pomodoroShortBreakLength
          this.suggestionsAmount = options.suggestionsAmount
          this.autoExpand = options.autoExpand
          this.reminder = options.reminder
          this.reminderDelay = options.reminderDelay
          this.reminderInterval = options.reminderInterval
          this.projectDetection = options.projectDetection
          this.clientLimitWarning = options.clientLimitWarning
          this.compactView = options.compactView
        }
      } catch (error) {
        console.error("Failed to load projects:", error);
      }
    },
    async saveOptions() {
      this.loading = true;
      await this.$makeRequest('post', '/_tasks/api/v1/time-tracker-settings', {
        pomodoroMode: this.pomodoroMode,
        pomodoroWorkLength: this.pomodoroWorkLength,
        pomodoroShortBreakLength: this.pomodoroShortBreakLength,
        autoExpand: this.autoExpand,
        reminder: this.reminder,
        suggestionsAmount: this.suggestionsAmount,
        reminderDelay: this.reminderDelay,
        reminderInterval: this.reminderInterval,
        projectDetection: this.projectDetection,
        clientLimitWarning: this.clientLimitWarning,
        compactView: this.compactView,
      })
      this.loading = false;
      this.$emit('refresh');
    },
  },
};
</script>
