<template>
    <div class="calendar-container">
        <div class="recap-stats" :class="{ 'compact-view': config.compactView }">
            <div class="recap-day-selector">
                <div class="grid grid-cols-24">
                    <div class="flex col-span-4 items-center justify-center cursor-pointer" @click="changeDate(-1)">
                        <svg-icon name="ArrowLeftIcon" />
                    </div>
                    <div class="col-span-5 recap-picker-prev cursor-pointer" @click="changeDate(-1)">
                        <div class="recap-picker-label">{{ getLabelForDate(this.daySelected, -1) }}</div>
                        <div class="recap-picker-date">{{ getShortDateForDate(this.daySelected, -1) }}</div>
                        <div class="recap-picker-hours flex justify-center">
                            <span v-if="loading">
                                <svg-icon name="LoadingIcon" :spin="true" />
                            </span>
                            <span v-else>{{ getHoursForOffset(-1) }}</span>
                        </div>
                    </div>
                    <div class="col-span-6 recap-picker-today cursor-pointer">
                        <VueDatePicker v-model="this.daySelected" format="dd/MM/yyyy" class="input-timeeditor-datepicker"
                            :dark="true" :month-change-on-scroll="false" :teleport="true" :enable-time-picker="false"
                            @update:model-value="handleDateChange">
                            <template #trigger>
                                <div class="recap-picker-label">{{ getLabelForDate(this.daySelected) }}</div>
                                <div class="recap-picker-date">{{ getShortDateForDate(this.daySelected) }}</div>
                                <div class="recap-picker-hours flex justify-center">
                                    <span v-if="loading">
                                        <svg-icon name="LoadingIcon" :spin="true" />
                                    </span>
                                    <span v-else>{{ getHoursForOffset(0) }}</span>
                                </div>
                            </template>
                            <!-- TODO: use slots to style picker https://vue3datepicker.com/slots/content/ -->
                        </VueDatePicker>


                    </div>
                    <div class="col-span-5 recap-picker-next cursor-pointer" @click="changeDate(1)">
                        <div class="recap-picker-label">{{ getLabelForDate(this.daySelected, 1) }}</div>
                        <div class="recap-picker-date">{{ getShortDateForDate(this.daySelected, 1) }}</div>
                        <div class="recap-picker-hours flex justify-center">
                            <span v-if="loading">
                                <svg-icon name="LoadingIcon" :spin="true" />
                            </span>
                            <span v-else>{{ getHoursForOffset(1) }}</span>
                        </div>
                    </div>
                    <div class="flex col-span-4 items-center justify-center cursor-pointer" @click="changeDate(1)">
                        <svg-icon name="ArrowRightIcon" />
                    </div>
                </div>
            </div>
            <div class="recap-chart">
                <div v-for="project in projectStats" :key="project.id" class="project-segment"
                    :style="{ width: project.widthPercentage + '%', backgroundColor: project.projectColour }"
                    @mouseover="showTooltip($event, project.projectName + ': ' + project.totalHours + ' hours')"
                    @mouseout="hideTooltip">
                </div>
                <div class="tooltip" v-if="tooltipVisible">{{ tooltipContent }}</div>
            </div>
        </div>
        <div class="time-list" id="calendar" :class="{ 'compact-view': config.compactView, 'calendar-list': timerRunning }"
            v-if="!loading">
            <ul class="list-unstyled" v-if="!timeEdited && classicList">
                <li class="w-100">
                    <ListTimeEntry v-for="(entry) in sortedTimeEntries" :key="entry.id" :timeEntry="entry" :dayMode="true"
                        @edit="openEditor" @expand="expandDate(date)" @continue="continueTimeEntry" />
                </li>
                <li class="w-100 see-more-time-entries">

                </li>
            </ul>
            <vue-cal ref="calendar" active-view="day" :selected-date="daySelected" :time-from="0" :time-to="24 * 60"
                :events="sortedTimeEntries" :disable-views="['years', 'year', 'month', 'week']"
                :time-cell-height="timeCellHeight" :time-step="timeStep"
                :split-days="splitDays" :sticky-split-labels="false" :min-cell-width="400"
                :editable-events="{ title: false, drag: true, resize: true, delete: true, create: true }"
                :on-event-create="onEventCreate" hide-title-bar hide-weekends hide-view-selector
                @event-delete="deleteEvent($event)" @event-duration-change="changeTimeEntryDuration($event)"
                @event-drop="changeTimeEntryDuration($event)" @ready="scrollToTime">
                <template #event="{ event }">
                    <div class="time-event grid grid-cols-24" :class="{ 'small-time-event': isContentSmall(event.id) }"
                        :ref="`time-event-${event.id}`" :style="getStyleForEntry(event)" @click="openEditor(event)">

                        <div class="flex flex-col col-span-18" v-if="!isContentHidden(event.id)">
                            <div class="event-title" v-if="event.description" v-html="parseDescription(event.title)"></div>
                            <div class="event-title" v-else>Click to add description</div>
                            <div class="event-project" v-if="!isContentTiny(event.id)">

                                <div v-if="event.project">{{ event.project.name }} / <span class="time-event-client">{{
                                    event.project.client.name }}</span>
                                </div>
                                <div v-else>Click to assign project</div>
                            </div>
                            <div class="flex-grow"></div>
                            <!--<div v-if="!event.id && event.split !== 2" class="dismiss-hint">Right click to dismiss</div> -->
                        </div>
                        <div class="col-span-6 list-time-segment text-right" v-if="!isContentHidden(event.id)">
                            <div class="list-time-container">
                                <div class="event-hours">{{ calculateHours(event) }}</div>
                                <div class="event-time" v-if="!isContentTiny(event.id)">{{ event.start.formatTime("HH:mm")
                                }} -
                                    {{ event.end.formatTime("HH:mm") }}</div>
                            </div>
                            <div class="list-continue-container w-full flex justify-end">
                                <button class="calendar-continue" @click.stop="($event) => continueTimeEntry($event, event)"
                                    v-if="event.end">
                                    <svg-icon name="StartIcon" />
                                </button>
                                <div v-else>
                                    <span v-if="event.end" class="">{{ convertSeconds(event.seconds) }}</span>
                                    <span class="float-right" v-else><font-awesome-icon :icon="['fas', 'clock']" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #time-cell="{ hours, minutes }">
                    <div :class="{ 'vuecal__time-cell-line': true, hours: !minutes }">
                        <strong v-if="!minutes" style="font-size: 15px">{{ displayTime(hours, minutes) }}</strong>
                        <span v-else style="font-size: 11px">{{ displayTime(hours, minutes) }}</span>
                    </div>
                </template>
            </vue-cal>
        </div>
        <div class="time-list time-list-loading" :class="{ 'compact-view': config.compactView }" v-else>
            <div class="flex justify-center">
                <svg-icon name="LoadingIcon" :spin="true" />
            </div>
        </div>
      <button class="btn-office-com" v-if="officeComEnabled" @click="toggleOfficeCom()" title="Open Office.com Calendar">
        <svg-icon name="OfficeComButtonIcon" />
      </button>
        <button class="btn-add-entry" @click="openEditor()" title="Create Time Entry">
            <svg-icon name="PlusIcon" />
        </button>
        <div class="zoom-buttons">
          <button @click="handleZoomIn" title="Zoom In">
            <svg-icon name="ZoomInIcon" />
          </button>
          <button @click="handleZoomOut" title="Zoom Out">
            <svg-icon name="ZoomOutIcon" />
          </button>
        </div>
    </div>
</template>

<script>
import ListTimeEntry from "@/components/tracker/ListTimeEntry.vue";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import _ from 'lodash';
import {useToast} from "vue-toastification";

export default {
    name: "Recap",
    components: { ListTimeEntry, SvgIcon, VueCal, VueDatePicker },
    setup() {
        const toast = useToast();
        return {
            toast
        }
    },
    data() {
        return {
            now: new Date(),
            classicList: false,
            officeComEnabled: false,
            showOfficeEntries: false,
            timeEntries: [],
            officeEntries: [],
            splitDays: [],
            timeEdited: null,
            taskTrackerUrl: null,
            daySelected: this.lastWorkingDay(),
            loading: true,
            loadingHours: true,
            loadingTimeEntries: true,
            tooltipVisible: false,
            tooltipContent: '',
            projectStats: [],
            timeCellHeight: 40,
            timeStep: 15,
            smallContentMap: {},
            tinyContentMap: {},
            hiddenContentMap: {},
            eventDeleteFunction: null,
            hours: {
                "-1": 0,
                "0": 0,
                "1": 0
            }
        };
    },
    props: {
        date: {
            type: String,
            required: false,
        },
        config: {
            type: Object,
            required: true,
        },
        timerRunning: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        const user = this.$store.getters.getUser;
        if(this.$store.getters.getOfficeViewEnabled && this.showOfficeEntries === false){
          this.toggleOfficeCom();
        }
        if(user && user.officecom_enabled){
            this.officeComEnabled = true;
        }
        this.taskTrackerUrl = process.env.VUE_APP_TASK_TRACKER_URL;
        if (this.date) {
            this.daySelected = this.date;
        }
        const userTimeStep = this.$store.getters.getUserTimeStep;
        if(userTimeStep){
            this.timeStep = userTimeStep;
        }
        this.loadTimeEntries();
        this.setHoursForSelection();
        window.addEventListener('wheel', this.handleScroll, { passive: false });
    },
    created() {
        this.debouncedSetHoursForSelection = _.debounce(this.setHoursForSelection, 500);
        this.debouncedLoadTimeEntries = _.debounce(this.loadTimeEntries, 500);
        this.debouncedLoadOfficeComEntries = _.debounce(this.loadOfficeComEntries, 500);
    },
    updated() {
        this.sortedTimeEntries.forEach(event => {
            const element = this.$refs[`time-event-${event.id}`];
            if (element) {
                this.hiddenContentMap[event.id] = element.clientHeight < 30;
                this.tinyContentMap[event.id] = element.clientHeight < 40;
                this.smallContentMap[event.id] = element.clientHeight < 50;
            }
        });
    },
    beforeUnmount() {
        window.removeEventListener('wheel', this.handleScroll);
    },
    computed: {
      sortedTimeEntries() {
        // Merge timeEntries and officeEntries into one array
        const allEntries = this.timeEntries.concat(this.officeEntries);

        // Sort the merged array
        const sortedEntries = allEntries.slice().sort((a, b) => {
          let dateA = new Date(a.start);
          let dateB = new Date(b.start);
          return dateA - dateB;
        });

        // Map over the sorted array to format each entry
        let events = sortedEntries.map(entry => {
          entry.title = entry.description;
          entry.start = new Date(entry.start);
          entry.end = new Date(entry.end);
          entry.start.setSeconds(0);
          entry.end.setSeconds(0);
          entry.class = 'time-entry';
          entry.draggable = true;

          // Set 'split' property based on the array origin
          entry.split = this.timeEntries.includes(entry) ? 1 : 2;

          return entry;
        });

        return events;
      }
    },
    methods: {
        async toggleOfficeCom(){
          if (this.showOfficeEntries){
            this.$store.dispatch("setOfficeComViewEnabled", false);
            this.showOfficeEntries = false;
            this.splitDays = [];
            this.officeEntries = [];
            return;
          }
          this.$store.dispatch("setOfficeComViewEnabled", true);
          this.showOfficeEntries = true;
          this.splitDays = [
            { class: '', 'label': 'Time Tracker' },
            { class: 'officecom-day', 'label': 'Office.com' }
          ];
          await this.loadOfficeComEntries();
        },
        refreshDayTime(){
            let totalSeconds = 0;
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(this.timeEntries)) {
                totalSeconds += value.seconds;
            }
            this.hours[0] = this.$convertSeconds(totalSeconds);

        },
        timeEntryUpdated(timeEntry) {
            for (const [key, value] of Object.entries(this.timeEntries)) {
                if(value.id && value.id === timeEntry.id){
                    this.timeEntries[key] = timeEntry;
                    this.refreshDayTime();
                    break;
                }
            }
        },
        timeEntryDeleted(timeEntry) {
            for (const [key, value] of Object.entries(this.timeEntries)) {
                if(value.id && value.id === timeEntry.id){
                    this.timeEntries.splice(key, 1);
                    this.refreshDayTime();
                    break;
                }
            }
        },
        async loadOfficeComEntries() {
          const day = this.daySelected;
          const from = `${day}T00:00:00`;
          const to = `${day}T23:59:59`;
          const data = {
            from: from,
            to: to,
          }
          const response = await this.$makeRequest("get", `/_tasks/api/v1/officecom-events`, data);
          if(response.error){
            this.toast.error(response.error);
            return;
          }
          if(response.data){
            this.officeEntries = response.data.data;
          }
        },
        async eventDrop(e) {
            await this.changeTimeEntryDuration(e);
        },
        async changeTimeEntryDuration(e) {
            const data = this.$constructPayload(e.event, e.event.project, false);
            if (data.hasErrors) {
                return;
            }

            try {
                if (e.event.id) {
                  return await this.$makeRequest("put", `/_tasks/api/v1/time-tracker/${e.event.id}`, data.payload);
                } else {

                  const createdResponse =  await this.$makeRequest("post", `/_tasks/api/v1/time-tracker`, data.payload);
                  this.timeEntries.push(createdResponse.data.data);
                }
            } catch (error) {
                console.error('An error occurred:', error);
                throw error;
            }
        },
        async deleteEvent(e) {
            await this.$makeRequest("delete", `/_tasks/api/v1/time-tracker/${e.id}`);
            this.$emit('event-delete', e);
        },
        onEventCreate(event, deleteEventFunction) {
            this.deleteEventFunction = deleteEventFunction

            return event
        },
        parseDescription(str) {
            return this.$parseTimeEntryDescription(str, 50);
        },
        logEvents(eventName, event) {
            console.log(eventName, event);
        },
        isContentHidden(eventId) {
            return this.hiddenContentMap[eventId];
        },
        isContentSmall(eventId) {
            return this.smallContentMap[eventId];
        },
        isContentTiny(eventId) {
            return this.tinyContentMap[eventId];
        },
        handleZoomIn(){
          if(this.timeStep > 5) {
            this.changeTimeStepBy(-5);
          }
        },
        handleZoomOut(){
          if(this.timeStep < 60) {
            this.changeTimeStepBy(5);
          }
        },
        handleScroll(event) {
            if (event.ctrlKey || event.metaKey) {
                event.preventDefault();
                let value = 0;
                if (event.deltaY < 0 && this.timeStep > 5) {
                  value = -5;
                }
                if (event.deltaY > 0 && this.timeStep < 60) {
                  value = 5;
                }
                this.changeTimeStepBy(value);
            }
        },
        changeTimeStepBy(value){
          const calendar = document.querySelector('#calendar');
          const segmentsDown = calendar.scrollTop / this.timeCellHeight;
          const minutesDown = segmentsDown * this.timeStep;
          this.timeStep += value;
          this.$store.dispatch("setUserTimeStep", this.timeStep);
          this.$nextTick(() => {
            const newScrollTop = (minutesDown / this.timeStep) * this.timeCellHeight;
            calendar.scrollTo({ top: newScrollTop });
          });
        },
        scrollToTime() {
            const calendar = document.querySelector('#calendar')
            // const hours = this.now.getHours() + this.now.getMinutes() / 60 - 6;
            const hours = 9;
            const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
            const timezoneOffsetInHours = timezoneOffsetInMinutes / -60;
            const hoursToScroll = hours + timezoneOffsetInHours;
            calendar.scrollTo({ top: hoursToScroll * this.timeCellHeight * (60 / this.timeStep) })
        },
        displayTime(hours, minutes) {
            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');

            return `${formattedHours}:${formattedMinutes}`;
        },
        calculateHours(event) {
            return this.$timeEntryDuration(event);
        },
        lastWorkingDay() {
            const today = new Date();
            return today.toISOString().split('T')[0]

            // below is depreciated but maybe we'll find a use for that?
            /*
            let lastWorkingDay;

            // If it's after 11 AM, return today
            if (today.getHours() >= 11) {
                lastWorkingDay = today;
            } else {
                // Otherwise, find the last working day based on the day of the week
                switch (today.getDay()) {
                    case 0: // Sunday
                        lastWorkingDay = new Date(today.setDate(today.getDate() - 2));
                        break;
                    case 1: // Monday
                        lastWorkingDay = new Date(today.setDate(today.getDate() - 3));
                        break;
                    default:
                        lastWorkingDay = new Date(today.setDate(today.getDate() - 1));
                        break;
                }
            }

            return lastWorkingDay.toISOString().split('T')[0];
            */
        },
        showTooltip(event, content) {
            this.tooltipContent = content;
            this.tooltipVisible = true;
        },
        hideTooltip() {
            this.tooltipVisible = false;
        },
        disableLoading() {
            if (!this.loadingHours && !this.loadingTimeEntries) {
                this.loading = false;
            }
        },
        enableLoading() {
            this.loading = true;
            this.loadingHours = true;
            this.loadingTimeEntries = true;
        },
        async changeDate(offset) {
            const modifiedDate = new Date(this.daySelected);
            modifiedDate.setDate(modifiedDate.getDate() + offset);
            //this.daySelected = modifiedDate.toISOString();
            this.daySelected = modifiedDate.toISOString().split('T')[0];
            await this.handleDateChange();
        },
        async handleDateChange() {
            this.daySelected = new Date(this.daySelected).toISOString().split('T')[0];
            this.enableLoading();
            this.$emit('dateSet', this.daySelected);
            await this.debouncedLoadTimeEntries();
            await this.debouncedSetHoursForSelection();
            if(this.showOfficeEntries){
              await this.debouncedLoadOfficeComEntries();
            }
        },
        getHoursForOffset(offset) {
            return this.hours[offset];
        },
        async setHoursForSelection() {
            const today = new Date(this.daySelected);
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);
            await Promise.all([
                this.downloadHoursForDate(today),
            ]);
            this.loadingHours = false;
            this.disableLoading();
        },
        async downloadHoursForDate(date) {
            this.$loadHoursCount(date, date)
                .then(response => {
                    this.hours = Object.keys(response.data.data).reduce((accumulator, key) => {
                        accumulator[key] = this.$convertTimeFloat(response.data.data[key]);
                        return accumulator;
                    }, {});
                })
                .catch(error => {
                    console.error("Error fetching hours :", error);
                });
        },
        async loadTimeEntries() {
            this.$loadTimeEntries(this.daySelected, this.daySelected)
                .then(response => {
                    this.timeEntries = response.data.data.data;
                    this.calculateProjectsStats();
                })
                .catch(error => {
                    console.error("Error fetching time entries :", error);
                    this.$store.dispatch("logoutUser");
                });
            this.loadingTimeEntries = false;
            this.disableLoading();
        },
        calculateProjectsStats() {
            const projectTime = new Map();

            this.timeEntries.forEach(entry => {
                const projectId = entry.project_id;
                if (projectId) {
                  if (projectTime.has(projectId)) {
                    let currentEntry = projectTime.get(projectId);
                    currentEntry.totalSeconds += entry.seconds;
                    projectTime.set(projectId, currentEntry);
                  } else {
                    projectTime.set(projectId, {
                      totalSeconds: entry.seconds,
                      projectName: entry.project.name,
                      projectColour: entry.project.colour
                    });
                  }
                }
            });

            const totalTime = Array.from(projectTime.values()).reduce((total, current) => {
                return total + current.totalSeconds;
            }, 0);

            const projectStats = Array.from(projectTime, ([projectId, data]) => ({
                id: projectId,
                projectName: data.projectName,
                projectColour: data.projectColour,
                totalSeconds: data.totalSeconds,
                totalHours: (data.totalSeconds / 3600).toFixed(2),
                widthPercentage: ((data.totalSeconds / totalTime) * 100).toFixed(2)
            }));

            this.projectStats = projectStats;
        },
        getShortDateForDate(date, offset = 0) {
            const modifiedDate = new Date(date);
            modifiedDate.setDate(modifiedDate.getDate() + offset);
            const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const weekDay = weekDays[modifiedDate.getDay()];
            const monthDay = modifiedDate.getDate();
            const month = months[modifiedDate.getMonth()];

            return `${weekDay} ${monthDay} ${month}`;
        },
        getLabelForDate(date, offset = 0) {
            const modifiedDate = new Date(date);
            modifiedDate.setDate(modifiedDate.getDate() + offset);

            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);

            today.setHours(0, 0, 0, 0);
            modifiedDate.setHours(0, 0, 0, 0);
            tomorrow.setHours(0, 0, 0, 0);
            yesterday.setHours(0, 0, 0, 0);

            if (modifiedDate.getTime() === today.getTime()) {
                return 'TODAY';
            } else if (modifiedDate.getTime() === tomorrow.getTime()) {
                return 'TOMORROW';
            } else if (modifiedDate.getTime() === yesterday.getTime()) {
                return 'YESTERDAY';
            } else {
                return modifiedDate.toLocaleDateString();
            }
        },
        calculateDayTime(timeEntries) {
            return timeEntries.reduce((acc, entry) => acc + entry.seconds, 0);
        },
        continueTimeEntry(e, timeEntry) {
            e.preventDefault();
            this.$emit('continue', timeEntry)
        },
        expandDate(date) {
            const index = this.expandedDates.indexOf(date);
            if (index !== -1) {
                this.expandedDates.splice(index, 1);
            } else {
                this.expandedDates.push(date);
            }
        },
        openEditor(timeEntry = null, e = null) {
            if (!timeEntry) {
                timeEntry = {}
            }
            if (e && e.button === 2) {
                e.preventDefault();
                if (this.deleteEventFunction) {
                    this.deleteEventFunction(timeEntry);
                }
                return;
            }
            this.timeEdited = timeEntry;
            this.$emit('editorOpen', timeEntry)
        },
        getStyleForEntry(entry) {
            if (!entry || entry.split === 2) {
                return {};
            }
            let bgColor
            if (entry.project) {
                bgColor = this.$addTransparencyToHexColor(entry.project.colour, 90);
            } else {
                bgColor = this.$addTransparencyToHexColor("#0D6072", 90);
            }
            return {
                backgroundColor: bgColor,
            };
        }
    },
};
</script>
  