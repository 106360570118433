<template>
    <div class="time-track-stats" :class="{ 'compact-view': config.compactView }" v-show="!loading">
        <div class="time-track-stats-header">
            <div class="flex flex-row justify-around">
                <div class="flex items-end justify-between text-center p-4">
                    <div>
                        <p class="stat-label">Hours This Week:</p>
                        <p class="stat-hour">{{ convertSeconds(stats.timeThisWeek) }}</p>
                    </div>
                </div>
                <div class="flex items-end justify-between text-center p-4">
                    <div>
                        <p class="stat-label">Hours This Month</p>
                        <p class="stat-hour">{{ convertSeconds(stats.timeThisMonth) }}</p>
                    </div>
                </div>
                <div class="flex items-end justify-between text-center p-4" v-if="stats.remainingTime">
                    <div>
                        <p class="stat-label">Remaining Hours</p>
                        <p class="stat-hour">{{ convertSeconds(stats.remainingTime) }}</p>
                    </div>
                </div>
            </div>
            <div class="ml-6 mr-6 mt-2" v-if="stats.remainingTime">
                <div class="relative pt-1">
                    <div class="w-full text-center">
                        <h1 class="stat-header">Month Progress</h1>
                    </div>
                    <div class="flex mb-2 items-center justify-end">
                        <span class="inline-block stat-progress-label">{{
                            convertSeconds(stats.timeThisMonth) }} / {{ convertSeconds(stats.timeThisMonth +
        stats.remainingTime) }}</span>
                    </div>
                    <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-px-green-three">
                        <div :style="{ width: monthProgress + '%' }"
                            class="shadow-none rounded flex flex-col text-center whitespace-nowrap text-white justify-center bg-px-yellow">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="time-track-stats-table">
            <div class="flex flex-row justify-around">
                <div class="w-full overflow-x-auto overflow-y">
                    <table class="w-full text-sm text-left mt-4 stats-table">
                        <tbody>
                            <tr v-for="(projectStats, projectId) in stats.perProjectHours" :key="projectId">
                                <td scope="col" class="px-6 py-3">
                                    <span class="stats-table-project" :style="'color: ' + projectStats.project.colour">
                                        {{ projectStats.project.name }}
                                    </span> | 
                                    <span class="stats-table-client" v-if="projectStats.project.client">
                                        {{ projectStats.project.client.name }}
                                    </span>
                                </td>
                                <td scope="col" class="px-6 py-3 text-right stats-table-hours">{{ convertSeconds(projectStats.seconds) }}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
    name: "TaskList",
    components: {},
    setup() {
        const toast = useToast();
        return {
            toast
        }
    },
    props: {
        config: {
            type: Object,
            required: true,
        },
        timerRunning: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            loading: true,
            stats: {},
        };
    },
    computed: {
        monthProgress() {
            const totalTime = this.stats.timeThisMonth + this.stats.remainingTime;
            return (this.stats.timeThisMonth / totalTime) * 100;
        }
    },
    async mounted() {
        await this.$makeRequest('get', '/_tasks/api/v1/time-tracker-stats').then((response) => {
            if (response.status === 200) {

                let stats = response.data.data;

                const processProjectHours = [];
                
                if (stats.perProjectHours) {
                    for (let prop in stats.perProjectHours) {
                        processProjectHours.push(stats.perProjectHours[prop]);
                    }

                    if (processProjectHours && processProjectHours.length > 0) {
                        processProjectHours.sort((a, b) => {
                            return b.seconds - a.seconds;
                        })
                    }

                    stats.perProjectHours = processProjectHours;
                }
                
                this.stats = stats;
                this.loading = false;
            } else {
                this.toast.error("Error refreshing user data");
            }
        }).catch((error) => {
            console.error(error);
            this.toast.error(error.response.data.message);
        })
    },
    methods: {
        convertSeconds(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = (seconds - (hours * 3600)) / 60;
            const result = hours + (minutes / 60);
            return this.$convertTimeFloat(result.toFixed(2));
        }
    }
}
</script>