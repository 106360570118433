import axios from "axios";
import moment from "moment";

const timeTrackerApi = {
  install(app) {
    app.config.globalProperties.$makeRequest = async function (
      method,
      url,
      data = {},
    ) {
      const token = this.$store.getters.getAuthToken;
      if (!token) return console.error("No authentication token available");

      const config = {
        method,
        url: process.env.VUE_APP_API_URL + url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (method === "get") {
        config.params = data;
      } else {
        config.data = data;
      }

      return axios(config);
    };
    app.config.globalProperties.$loadTimeEntries = function (
      dateFrom,
      dateTo,
      page = 1,
      perPage = 100,
    ) {
      const token = this.$store.getters.getAuthToken;

      if (!token) {
        console.error("No authentication token available");
        return Promise.reject(new Error("No authentication token available"));
      }

      return axios.get(
        process.env.VUE_APP_API_URL + "/_tasks/api/v1/time-tracker",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: page,
            perPage: perPage,
            dateFrom: dateFrom,
            dateTo: dateTo,
          },
        },
      );
    };
    app.config.globalProperties.$loadHoursCount = function (dateFrom, dateTo) {
      const token = this.$store.getters.getAuthToken;

      if (!token) {
        console.error("No authentication token available");
        return Promise.reject(new Error("No authentication token available"));
      }

      return axios.get(
        process.env.VUE_APP_API_URL + "/_tasks/api/v1/time-tracker-recap-hours",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            dateFrom: dateFrom,
            dateTo: dateTo,
          },
        },
      );
    };
    app.config.globalProperties.$loadTimeEntriesGrouped = function () {
      const token = this.$store.getters.getAuthToken;

      if (!token) {
        console.error("No authentication token available");
        return Promise.reject(new Error("No authentication token available"));
      }

      return axios.get(
        process.env.VUE_APP_API_URL + "/_tasks/api/v1/time-tracker-grouped",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    };
    app.config.globalProperties.$getSuggestions = function (query) {
      const token = this.$store.getters.getAuthToken;

      if (!token) {
        console.error("No authentication token available");
        return Promise.reject(new Error("No authentication token available"));
      }
      return axios.get(process.env.VUE_APP_API_URL + "/_tasks/api/v1/time-entry-suggestions?query=" + query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response;
      });
    };
    app.config.globalProperties.$login = function (email, password) {
      return axios.post(process.env.VUE_APP_API_URL + "/_user/api/v2/login", {
        email: email,
        password: password,
      })
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch("storeAuthToken", response.data.token);
            this.$store.dispatch("storeUser", response.data.user);
            this.notifyServiceWorker(response.data);
            this.$emit("signedIn");
          }
          return response; // Useful if you want to do further handling outside.
        });
    };
    app.config.globalProperties.$searchInProjects = function (
      groupedProjects,
      projectSearchTerm,
    ) {
      let response = Object.entries(groupedProjects).reduce(
        (acc, [clientName, projects]) => {
          const clientNameMatches = clientName.toLowerCase().includes(
            projectSearchTerm.toLowerCase(),
          );
          const filteredProjects = projects.filter((project) => {
            return project.name.toLowerCase().includes(
              projectSearchTerm.toLowerCase(),
            );
          });

          if (clientNameMatches || filteredProjects.length > 0) {
            acc[clientName] = clientNameMatches ? projects : filteredProjects;
          }

          return acc;
        },
        {},
      );

      return response;
    },
      app.config.globalProperties.$searchInProjectsById = function (
        groupedProjects,
        projectId,
      ) {
        const map = Object.entries(groupedProjects).flatMap((entry) => {
          return entry[1];
        });
        const results = map.filter((project) => {
          return project.id === projectId;
        });

        if (results.length > 0) {
          return results[0];
        }
        return {};
      },
      app.config.globalProperties.$constructPayload = function (
        time,
        selectedProject,
        liveEntryUpdate = false,
      ) {
        let payload = {};
        let errors = {};
        errors.descriptionError = !time.description;
        if (!errors.descriptionError) payload.description = time.description;

        errors.projectError = !selectedProject;
        if (!errors.projectError) payload.project_id = selectedProject.id;

        errors.dateStartError = !time.start;
        if (!errors.dateStartError) {
          payload.start = localDateToUTC(
            time.start,
          );
        }

        if (!liveEntryUpdate) {
          errors.dateEndError = !time.end;
          if (!errors.dateEndError) {
            payload.end = localDateToUTC(
              time.end,
            );
          }
        }
        payload.billable = time.billable;

        return {
          payload: payload,
          errors: errors,
          hasErrors: hasErrors(errors),
        };
      };
  },
};

function hasErrors(errors) {
  return errors.descriptionError || errors.projectError ||
    errors.dateStartError || errors.dateEndError;
}

function localDateToUTC(localDate) {
  return moment(localDate).utc().format("YYYY-MM-DD HH:mm:ss");
}

export default timeTrackerApi;
