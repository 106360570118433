<template>
  <div class="project-input-container">
    <label>Project</label>
    <input
        type="text"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        placeholder="Project"
        class="form-input p-2 border rounded w-full"
        :class="[classes, { 'red-border': projectError }]"
    />
    <ul class="p-2 w-full project-search-list" v-if="Object.keys(searchProjects).length">
      <li v-for="(projects, clientName) in searchProjects" :key="clientName">
        <div class="font-bold mt-3">{{ clientName }}</div>
        <ul>
          <li
              class="w-full p-2 border-b cursor-pointer project-search-item"
              v-for="project in projects"
              :key="project.name"
              @click="selectProject(project)"
          >
            <span class="project-search-item-title">{{ project.name }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ProjectSearchInput',
  props: {
    modelValue: String, // This will hold the project search term
    projectError: Boolean,
    searchProjects: Object,
    classes: String
  },
  emits: ['update:modelValue', 'selectProject'],
  methods: {
    selectProject(project) {
      this.$emit('selectProject', project);
    }
  }
};
</script>