<template>
  <div class="site">
    <div class="flex min-h-full flex-col justify-center w-1/2 m-0 m-auto mt-16">
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <img class="mx-auto w-auto mb-36" src="https://api.tasks.uat.pxpx.dev/themes/default/assets/images/tt.jpg"
          alt="PixelPixel TaskTracker" />
      </div>
      <div class="sm:mx-auto sm:w-full sm:max-w-sm text-center">
        <h1>Continue with your browser...</h1>
        <button class="mt-2" @click="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  name: "OfficeSignIn",
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast }
  },
  components: {},
  data() {
    return {
      email: "",
      password: "",
    };
  },
  async mounted() {
    if (process.env.VUE_APP_ENV === 'extension') {
      chrome.storage.local.get('tt_jwt_token', async (obj) => {
        if (obj.tt_jwt_token) {
          await this.signInToken(obj.tt_jwt_token);
        }
      });
    }
    if (process.env.VUE_APP_ENV === 'web') {
      const queryParams = new URLSearchParams(window.location.search);
      const jwtTokenArrived = queryParams.get('jwt_token');
      if (jwtTokenArrived) {
        await this.signInToken(jwtTokenArrived);
        window.close();
      }
    }
  },
  computed: {
    defaultText() {
      return browser.i18n.getMessage("extName");
    },
  },
  methods: {
    async signInToken(token) {
      try {
        await this.$store.dispatch("storeAuthToken", token);
        const response = await this.$makeRequest(
          "get",
          "/_user/api/v2/fetch"
        );
        if (response.data) {
          let payload = {
            token: token,
            user: response.data
          }
          this.$store.dispatch("storeUser", response.data);
          if (process.env.VUE_APP_ENV === 'extension') {
            this.notifyServiceWorker(payload);
          }
          this.$emit("success");
        }
      } catch (error) {
        this.$store.dispatch("storeAuthToken", null);
        console.error(error);
        this.toast.error(error.message);
      }
    },
    cancel() {
      this.$store.dispatch("storeAuthToken", null);
      this.$store.dispatch("setOfficeProcess", false);
    },
    submit() {
      axios
        .post(process.env.VUE_APP_API_URL + "/_user/api/v2/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch("storeAuthToken", response.data.token);
            this.$store.dispatch("storeUser", response.data.user);
            this.notifyServiceWorker(response.data);
            this.$emit("signedIn");
          } else {
            this.toast.error("Invalid credentials");
          }
        }).catch((error) => {
          console.error(error);
          this.toast.error(error.response.data.message);
        })
    },
    notifyServiceWorker(data) {
      chrome.runtime.sendMessage({
        action: 'userDataReceived',
        data: data
      });
    }
  },
};
</script>