<template>
  <div class="site">
    <time-tracker-navbar :tab="page" @refresh="fullRefresh()" @logout="logout()" @stats="openStats()" @home="openHome()" @options="openOptions()"
      @recap="openRecap()" />
    <div class="time-edit" :class="{ 'compact-view': config.compactView }" v-if="page === 'editor'">
      <time-edit :time-entry="timeEdited" :mode="editorMode" @update="timeEditedUpdated" :return="returnPage"
        :return-params="returnParams" :timer-running="timerRunning" @cancel="timeEditedCanceled" />
    </div>
    <time-track :time="runningTime" :projects="projects" ref="timeTrack" v-show="page === 'home'" :config="config"
      @update="refresh" @start="handleTimeStart()" @stop="handleTimeStop()" />
    <stats ref="stats" v-if="page === 'stats'" :config="config" :timer-running="timerRunning" />
    <recap ref="recap" v-if="page === 'recap'" :config="config" :date="dateSelected" @editor-open="editorOpen"
      :timer-running="timerRunning" @continue="continueTimeEntry" @editor-close="closeEditor()"
      @date-set="updateCalendarDate" />
    <extensions-options ref="options" v-if="page === 'options'" @refresh="fullRefresh()" />
    <time-list ref="taskList" v-if="page === 'home'" :config="config" @editor-open="editorOpen"
      @editor-close="closeEditor()" @continue="continueTimeEntry" />
    <time-tracker-footer v-if="runningTime && page !== 'home'" :time="runningTime" :timer-running="timerRunning"
      :projects="projects" @update="refresh" />
  </div>
</template>

<script>
import { io } from "socket.io-client";
import TimeList from "@/components/tracker/TimeList.vue";
import TimeTrack from "@/components/tracker/TimeTrack.vue";
import TimeEdit from "@/components/editor/TimeEdit.vue";
import ExtensionsOptions from "@/components/ExtensionOptions.vue";
import TimeTrackerNavbar from "@/components/layout/TimeTrackerNavbar.vue";
import TimeTrackerFooter from "@/components/layout/TimeTrackerFooter.vue";
import Stats from "@/components/tracker/Stats.vue"
import Recap from "@/components/tracker/Recap.vue"

export default {
  name: "TimeTracker",
  components: { Stats, TimeList, TimeTrack, TimeEdit, TimeTrackerNavbar, TimeTrackerFooter, Recap, ExtensionsOptions },
  data() {
    return {
      io: null,
      page: "home",
      returnPage: "home",
      runningTime: {},
      projects: [],
      timeEdited: {},
      timerRunning: false,
      config: {
        compactView: true
      },
      returnParams: null,
      dateSelected: null
    };
  },
  computed: {
    editorMode() {
      if (this.timeEdited) {
        if (this.timeEdited.id) {
          return 'edit';
        } else {
          return 'create';
        }
      }
      return 'edit';
    },
  },
  async mounted() {
    const user = this.$store.getters.getUser;
    await this.loadProjects();
    await this.loadCurrentTime();
    if (user) {
      this.$nextTick(() => {
        this.config = user.timetracker_options;
        this.io = io(process.env.VUE_APP_WEBSOCKET_URL, {
          extraHeaders: {
            "x-session-token": user.session_token,
          },
        });
        this.setupListeners()
      });
    }
  },
  methods: {
    setupListeners() {
      const component = this;
      this.io.on('private', function (msg) {
        if (!msg.payload) {
          return;
        }
        if (msg.payload.type === 'system') {
          if (msg.payload.title === 'timeEntryUpdate') {
            component.processTimeEntryUpdate(msg.payload);
          }
          if (msg.payload.title === 'taskEntryDelete') {
            component.processTimeEntryDelete(msg.payload);
          }
        }
        if (msg.payload.type === 'notification') {
          component.processNotification(msg.payload);
        }
      })
    },
    async processNotification(payload) {
      if (!window) {
        return;
      }
      const options = {
        icon: './img/icons/android-chrome-192x192.png',
        body: payload.content,
        requireInteraction: payload.sticky,
      };

      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        await this.triggerNotification(payload, options);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(async (permission) => {
          if (permission === "granted") {
            await this.triggerNotification(payload, options);
          }
        });
      }
    },
    async triggerNotification(payload, options){
        // eslint-disable-next-line no-unused-vars
        const notification = new Notification(payload.title, options);
        try {
          const audio = new Audio('./notification.mp3');
          await audio.play();
        } catch (error) {
          // this is expected
        }
    },
    processTimeEntryUpdate(payload) {
      const content = JSON.parse(payload.content);
      if(content.user_id !== this.$store.getters.getUser.id){
        return;
      }
      if (this.page === 'home') {
        this.$refs.timeTrack.timeEntryUpdated(content);
        this.$refs.taskList.timeEntryUpdated(content);
        if (this.runningTime && this.runningTime.id === content.id) {
          if (content.end) {
            this.runningTime = {};
            this.timerRunning = false;
          }
        }
        if (!this.runningTime && !content.end) {
          this.runningTime = content;
          this.timerRunning = true;
        }
      }
      if (this.page === 'recap') {
        this.$refs.recap.timeEntryUpdated(content);
      }
    },
    processTimeEntryDelete(payload) {
      const content = JSON.parse(payload.content);
      if (this.page === 'home') {
        this.$refs.taskList.timeEntryDeleted(content);
        if (this.runningTime && this.runningTime.id === content.id) {
          this.runningTime = {};
          this.timerRunning = false;
        }
      }
      if (this.page === 'recap') {
        this.$refs.recap.timeEntryDeleted(content);
      }
    },
    openHome() {
      this.returnPage = this.page;
      this.page = 'home';
      this.timeEdited = null;
    },
    openStats() {
      this.returnPage = this.page;
      this.page = 'stats';
      this.timeEdited = null;
    },
    openOptions() {
      this.returnPage = this.page;
      this.page = 'options';
      this.timeEdited = null;
    },
    openRecap() {
      this.returnPage = this.page;
      this.page = 'recap';
      this.timeEdited = null;
    },
    async handleTimeStart() {
      await this.notifyServiceWorkerOnStart();
    },
    async handleTimeStop() {
      this.runningTime = {};
      this.timerRunning = false;
      await this.notifyServiceWorkerOnStop();
    },
    async loadCurrentTime() {
      try {
        const response = await this.$makeRequest(
          "get",
          "/_tasks/api/v1/time-tracker/current"
        );
        if (response.data && response.data.data) {
          this.runningTime = response.data.data;
          this.timerRunning = true;
          const allProjects = Object.values(this.projects).flat();
          this.selectedProject = allProjects.find(project => project.id === this.runningTime.project_id)
          if (this.selectedProject) {
            this.projectSearchTerm = this.selectedProject.name
          }
        }
      } catch (error) {
        if (!error.response || error.response.status !== 404) {
          console.error("Failed to load the current time:", error);
        }
      }
    },

    async loadProjects() {
      try {
        const response = await this.$makeRequest(
          "get",
          "/_tasks/api/v1/clients-projects"
        );
        this.projects = response.data.data;
      } catch (error) {
        console.error("Failed to load projects:", error);
      }
    },
    continueTimeEntry(timeEntry) {
      this.$refs.timeTrack.continueTimeEntry(timeEntry);
    },
    async fullRefresh() {
      await this.refreshUser();
      const user = this.$store.getters.getUser;
      await this.loadProjects();
      await this.loadCurrentTime();
      this.notificationsPermissions();
      if (this.$refs.taskList) {
        this.$refs.taskList.loadTimeEntries();
      }
      if (user) {
        this.$nextTick(() => {
          this.config = user.timetracker_options;
        });
      }
    },
    async refresh(runningTime) {
      this.notificationsPermissions();
      this.runningTime = runningTime;
      if (runningTime) {
        this.timerRunning = Object.keys(runningTime).length > 0;
      }
      if (this.$refs.taskList) {
        this.$refs.taskList.loadTimeEntries();
      }
      await this.refreshUser();
    },
    logout() {
      this.$emit('logout')
    },
    editorOpen(timeEntry = null) {
      this.returnPage = this.page;
      this.page = 'editor';
      this.timeEdited = timeEntry;
    },
    timeEditedUpdated(event) {
      if (event.timeEntry && !event.timeEntry.end) {
        this.runningTime = event.timeEntry;
      }
      this.closeEditor(event.page, event.params);
    },
    timeEditedCanceled(event) {
      this.closeEditor(event.page, event.params);
    },
    closeEditor(page, params) {
      this.timeEdited = null;
      this.return = null;
      if (page) {
        this.page = page;
      } else {
        this.page = 'home';
      }
      if (params && params.date) {
        this.dateSelected = params.date;
      }
    },
    updateCalendarDate(date) {
      this.returnParams = {
        date: date
      }
    },
    async refreshUser() {
      await this.$makeRequest('get', '/_user/api/v2/fetch').then((response) => {
        if (response.status === 200) {
          this.$store.dispatch("storeUser", response.data);
        } else {
          this.toast.error("Error refreshing user data");
        }
      }).catch((error) => {
        console.error(error);
        this.toast.error(error.response.data.message);
      })
    },
    async notifyServiceWorkerOnStart() {
      if (process.env.VUE_APP_ENV === 'extension') {
        chrome.runtime.sendMessage({
          action: 'startTimeTracking', data: {
            pomodoroWorkLength: this.config.pomodoroWorkLength
          }
        }, function (response) {
          console.log(response);
        });
      }
    },
    async notifyServiceWorkerOnStop() {
      if (process.env.VUE_APP_ENV === 'extension') {
        chrome.runtime.sendMessage({
          action: 'stopTimeTracking', data: {
            pomodoroWorkLength: this.config.pomodoroWorkLength
          }
        }, function (response) {
          console.log(response);
        });
      }
    },
    notificationsPermissions() {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        } else {
          console.error('Notification permission denied.');
        }
      });
    },
  },
};
</script>
