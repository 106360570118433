const coloursUtils = {
  install(app) {
    app.config.globalProperties.$getContrastingColor = function (hexColor) {
      const r = parseInt(hexColor.substr(1, 2), 16);
      const g = parseInt(hexColor.substr(3, 2), 16);
      const b = parseInt(hexColor.substr(5, 2), 16);
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;

      return brightness > 128 ? "#000000" : "#FFFFFF";
    };
    app.config.globalProperties.$toPastelHexColor = function (
      hexColor,
      saturationDecrease = 0.5,
      lightnessIncrease = 0.3,
    ) {
      let { r, g, b } = hexToRgb(hexColor);

      // Convert RGB to HSL
      let { h, s, l } = rgbToHsl(r, g, b);

      // Decrease Saturation and increase Lightness to get a pastel color
      s -= s * saturationDecrease; // decrease saturation by 50%
      l += (1 - l) * lightnessIncrease; // increase lightness by 30% towards white

      // Make sure lightness and saturation are within bounds (0 to 1)
      s = Math.max(0, Math.min(s, 1));
      l = Math.max(0, Math.min(l, 1));

      // Convert HSL back to RGB
      ({ r, g, b } = hslToRgb(h, s, l));

      // Convert RGB back to hex
      return rgbToHex(r, g, b);
    };
    app.config.globalProperties.$addTransparencyToHexColor = function(hex, transparency) {
        // Ensure the transparency is within the range of 0 - 255
        transparency = Math.min(255, Math.max(0, transparency));
        
        // Convert transparency to a two-character hexadecimal string
        const alphaHex = transparency.toString(16).padStart(2, '0');
        
        // Check if the input hex color is in the format of #RRGGBB or #RGB
        if (hex.length === 7) {
            // If #RRGGBB, return as is
            return `${hex}${alphaHex}`;
        } else if (hex.length === 4) {
            // If #RGB, convert to #RRGGBB
            const r = hex[1];
            const g = hex[2];
            const b = hex[3];
            return `#${r}${r}${g}${g}${b}${b}${alphaHex}`;
        } else {
            // Invalid hex color
            throw new Error('Invalid hex color format');
        }
    }
    app.config.globalProperties.$lightenHexColour = function (
      hexColor,
      percent,
    ) {
      // Convert hex to RGB
      let r = parseInt(hexColor.slice(1, 3), 16);
      let g = parseInt(hexColor.slice(3, 5), 16);
      let b = parseInt(hexColor.slice(5, 7), 16);

      // Convert RGB to HSL
      r /= 255;
      g /= 255;
      b /= 255;
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h, s, l = (max + min) / 2;

      if (max === min) {
        h = s = 0; // achromatic
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
      }

      // Lighten color
      l = l + percent * (1.0 - l);

      // Convert HSL back to RGB
      function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      }

      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);

      // Convert RGB back to hex
      const toHex = (x) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };
  },
};

// Helper function to convert hex to RGB
function hexToRgb(hex) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
}

// Helper function to convert RGB to HSL
function rgbToHsl(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h, s, l };
}

// Helper function to convert HSL to RGB
function hslToRgb(h, s, l) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return { r: r * 255, g: g * 255, b: b * 255 };
}

// Helper function to convert RGB to Hex
function rgbToHex(r, g, b) {
  return "#" +
    ((1 << 24) + (Math.round(r) << 16) + (Math.round(g) << 8) + Math.round(b))
      .toString(16).slice(1);
}

export default coloursUtils;
