<template>
  <div class="site">
    <office-sign-in v-if="isInOfficeProcess" @success="signedIn()" />
    <div class="flex min-h-full flex-col justify-center w-1/2 m-0 m-auto mt-12" v-if="!isInOfficeProcess">
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <img class="mx-auto w-auto mb-24" src="https://api.tasks.uat.pxpx.dev/themes/default/assets/images/tt.jpg"
          alt="PixelPixel TaskTracker" />
      </div>
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <form class="space-y-6" @submit.prevent="submit">
          <div>
            <div class="mt-2">
              <input id="email" name="email" type="email" autocomplete="email" required v-model="email"
                placeholder="Enter your e-mail" class="block w-full signin-input" />
            </div>
          </div>

          <div>
            <div class="mt-2">
              <input id="password" name="password" type="password" autocomplete="current-password" required
                v-model="password" placeholder="Enter your password" class="block w-full signin-input" />
              <a class="forgot-password-button float-right mt-2" target="_blank" href="#">Forgot Password</a>
            </div>
          </div>

          <div>
            <button type="submit" class="flex justify-center btn-px m-0 m-auto mt-16">
              <svg-icon name="LoginIcon"></svg-icon> Login
            </button>
            <button type="button" @click="loginWithOffice" class="flex justify-center btn-px btn-office m-0 mt-6 m-auto">
              <svg-icon name="Office365Icon"></svg-icon> Login with Office365
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import OfficeSignIn from "@/components/OfficeSignIn.vue";

export default {
  name: "SignIn",
  setup() {
    const toast = useToast();
    return { toast }
  },
  components: { SvgIcon, OfficeSignIn },
  data() {
    return {
      email: "",
      password: "",
      isLoading: false
    };
  },
  mounted() {
    const queryParams = new URLSearchParams(window.location.search);
    const jwtTokenArrived = queryParams.get('jwt_token');
    if (jwtTokenArrived) {
      this.$store.dispatch("setOfficeProcess", true);
    }
  },
  computed: {
    isInOfficeProcess() {
      return this.$store.getters.isInOfficeProcess;
    },
  },
  methods: {
    async loginWithOffice() {
      this.$store.dispatch("setOfficeProcess", true);
      if (process.env.VUE_APP_ENV === 'extension') {
        chrome.tabs.create({ url: process.env.VUE_APP_OFFICE_LOGIN_URL + "?m=extension" });
      } else {
        const pwaUrl = process.env.VUE_APP_OFFICE_LOGIN_URL + "?m=pwa"
        const popup = window.open(
          pwaUrl,
          'loginPopup',
          'width=500,height=500'
        )
        const checkPopupClosed = setInterval(async () => {
          console.log('checking popup closed', popup.closed)
          if (popup.closed) {
            const jwtToken = popup.location.href.match(/jwt_token=([^&]+)/)[1];

            await this.$store.dispatch("storeAuthToken", jwtToken);
            this.isLoading = true
            clearInterval(checkPopupClosed)
            this.$store.dispatch("setOfficeProcess", false);
            this.$emit("signedIn");
          }
        }, 100)
      }
    },
    signedIn() {
      this.$store.dispatch("setOfficeProcess", false);
      this.$emit("signedIn");
    },
    submit() {
      axios
        .post(process.env.VUE_APP_API_URL + "/_user/api/v2/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch("storeAuthToken", response.data.token);
            this.$store.dispatch("storeUser", response.data.user);
            this.notifyServiceWorker(response.data);
            this.$emit("signedIn");
          } else {
            this.toast.error("Invalid credentials");
          }
        }).catch((error) => {
          console.error('ERROR', error);
          if(error.response && error.response.data){
            this.toast.error(error.response.data.message);
          }
          if(error.message){
            this.toast.error(error.message);
          }
        })
    },
    notifyServiceWorker(data) {
      if (process.env.VUE_APP_ENV === 'extension') {
        chrome.runtime.sendMessage({
          action: 'userDataReceived',
          data: data
        });
      }
    }
  },
};
</script>