<template>
  <div>
    <div v-if="signIn">
      <time-tracker @logout="logout()" />
    </div>
    <div v-else>
      <sign-in @signedIn="signedIn" />
    </div>
    <VuePreloader background-color="#1c3039" color="#F17F22" transition-type="fade-up" :loading-speed="5"
      :transition-speed="0">
    </VuePreloader>
  </div>
</template>

<script>

import SignIn from "@/components/SignIn.vue";
import TimeTracker from "@/components/TimeTracker.vue";
import { VuePreloader } from 'vue-preloader';
import '@/../node_modules/vue-preloader/dist/style.css'

export default {
  name: "App",
  components: { SignIn, TimeTracker, VuePreloader },
  data() {
    return {
      signIn: false,
    };
  },
  mounted() {
    const token = this.$store.getters.getAuthToken;
    if (token) {
      this.signIn = true;
    }
  },
  methods: {
    signedIn() {
      this.signIn = true;
    },
    logout() {
      this.$store.dispatch("logoutUser");
      if (process.env.VUE_APP_ENV === 'extension') {
        chrome.storage.local.set({ 'tt_jwt_token': null }, () => {

        });
      }
      // todo - for some reason this is done all the time
      // chrome.runtime.sendMessage({
      //   action: 'userDataDeleted'
      // });
      this.signIn = false;
    },
  },
};
</script>

<style></style>
