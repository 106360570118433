<template>
  <div>
    <div class="time-list" :class="{ 'compact-view': config.compactView }">
      <ul class="list-unstyled" v-if="!timeEdited">
        <li class="w-100" v-for="(dayEntries, date) in timeEntries" :key="date">
          <ListTimeEntry :timeEntry="dayEntries[0]" :date="date" :amount="dayEntries.length" :first="true"
            :dayTime="calculateDayTime(dayEntries)" @edit="openEditor" @expand="expandDate(date)"
            @continue="continueTimeEntry" />
          <Transition name="custom-classes" enter-active-class="animate__animated animate__bounceIn"
            leave-active-class="animate__animated animate__bounceOut">
            <div v-show="expandedDates.includes(date)">
              <ListTimeEntry v-for="(entry, index) in dayEntries.slice(1)" :key="index" :timeEntry="entry" :date="date"
                @edit="openEditor" @expand="expandDate(date)" @continue="continueTimeEntry" />
            </div>
          </Transition>
        </li>
        <li class="w-100 see-more-time-entries">
          <a :href="taskTrackerUrl" target="_blank" class="button">See More on TaskTracker</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ListTimeEntry from "@/components/tracker/ListTimeEntry.vue";
export default {
  name: "TimeList",
  components: { ListTimeEntry },
  data() {
    return {
      timeEntries: {},
      expandedDates: [],
      timeEdited: null,
      taskTrackerUrl: null
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.taskTrackerUrl = process.env.VUE_APP_TASK_TRACKER_URL;
    this.loadTimeEntries();
  },
  methods: {
    timeEntryUpdated(timeEntry) {
      const entryDate = timeEntry.start.split('T')[0];
      if (!this.timeEntries[entryDate]) {
        this.timeEntries[entryDate] = [];
      }
      const index = this.timeEntries[entryDate].findIndex(entry => entry.id === timeEntry.id);

      if (index !== -1) {
        this.timeEntries[entryDate][index] = timeEntry;
      } else {
        this.timeEntries[entryDate].push(timeEntry);
      }

      this.timeEntries[entryDate].sort((a, b) => new Date(b.start) - new Date(a.start));
    },
    timeEntryDeleted(timeEntry) {
      for (const date in this.timeEntries) {
        if (Object.prototype.hasOwnProperty.call(this.timeEntries, date)) {
          const index = this.timeEntries[date].findIndex(entry => entry.id === timeEntry.id);
          if (index !== -1) {
            this.timeEntries[date].splice(index, 1);
            if (this.timeEntries[date].length === 0) {
              delete this.timeEntries[date];
            }
            break;
          }
        }
      }
    },
    calculateDayTime(timeEntries) {
      return timeEntries.reduce((acc, entry) => acc + entry.seconds, 0);
    },
    continueTimeEntry(timeEntry) {
      this.$emit('continue', timeEntry)
    },
    expandDate(date) {
      const index = this.expandedDates.indexOf(date);
      if (index !== -1) {
        this.expandedDates.splice(index, 1);
      } else {
        this.expandedDates.push(date);
      }
    },
    loadTimeEntries() {
      this.$loadTimeEntriesGrouped()
        .then(response => {
          this.timeEntries = response.data.data;
          if(this.config.autoExpand) {
            this.expandedDates = Object.keys(this.timeEntries);
          }
        })
        .catch(error => {
          console.error("Error fetching time entries :", error);
          this.$store.dispatch("logoutUser");
        });
    },
    openEditor(timeEntry) {
      this.timeEdited = timeEntry;
      this.$emit('editorOpen', timeEntry)
    }
  },
};
</script>

<style></style>