import { createApp } from 'vue';
import App from './App.vue';
import store from '@/store';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlay, faStop, faSignOut, faClock, faTrash, faUser, faRefresh, faTag, faFolder, faDollarSign, faLink } from '@fortawesome/free-solid-svg-icons'
import timeTrackerApi from '@/plugins/timeTrackerApi.js';
import timeUtils from '@/plugins/timeUtils.js';
import coloursUtils from '@/plugins/coloursUtils.js';
import Toast from "vue-toastification";
import '@/assets/css/tailwind.scss';
import '@/assets/css/toastification.scss';
import '@/assets/css/style.scss';
import 'animate.css';
library.add(faPlay, faStop, faSignOut, faClock, faTrash, faUser, faRefresh, faTag, faFolder, faDollarSign, faLink)

const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon);
const toastOptions = {
    // You can set your default options here
};

app.use(Toast, toastOptions);
app.use(timeTrackerApi);
app.use(coloursUtils);
app.use(timeUtils);
app.use(store);
app.mount('#app');

// eslint-disable-next-line no-unused-vars
const resizeViewPort = function(width, height) {
    if (window.outerWidth) {
        window.resizeTo(
            width + (window.outerWidth - window.innerWidth),
            height + (window.outerHeight - window.innerHeight)
        );
    } else {
        window.resizeTo(500, 500);
        window.resizeTo(
            width + (500 - document.body.offsetWidth),
            height + (500 - document.body.offsetHeight)
        );
    }
};

document.addEventListener("DOMContentLoaded", () => {
    // DO NOT REMOVE THIS! It's used for Chrome Extension!
    if (typeof chrome !== "undefined" && chrome.runtime && chrome.runtime.id) {
        // It's a Chrome extension
        document.documentElement.classList.add('chrome-extension-context');
    } else {
        // It's a regular web page
        document.documentElement.classList.add('browser-context');
    }
 });