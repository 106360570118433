<template>
  <div class="time-track" :class="{ 'compact-view': config.compactView }">
    <div class="mb-3 flex flex-row">
      <div class="flex-grow">
        <div class="timetracker-input-container">
          <DescriptionInput
            :runningTime="runningTime"
            :descriptionError="descriptionError"
            @updateDescription="updateDescription"
            classes="input-timetracker"
            @descriptionFocused="descriptionEdited = true"
            @descriptionBlurred="descriptionEdited = false"
            @selectProject="selectProject"
          />
          <ProjectSearchInput
              :modelValue="projectSearchTerm"
              @update:modelValue="projectSearchTerm = $event"
              :projectError="projectError"
              :searchProjects="searchProjects"
              classes="input-timetracker"
              @selectProject="selectProject"
          />
        </div>
      </div>
      <div class="flex flex-col items-end running-time-container">
        <div v-if="!runningTime.start" class="">
          <button @click="submitStart" class="btn-start">
            <svg-icon name="StartIcon" />
          </button>
        </div>
        <div v-if="runningTime.start" class="flex flex-row items-center">
          <div class="time pr-2" v-if="elapsedTime !== 'NaN:NaN:NaN'" v-html="elapsedTime"></div>
          <div class="flex items-center">
            <button @click="submitStop" @contextmenu="submitCancel($event)" class="btn btn-stop">
              <svg-icon name="StopIcon" />
            </button>
          </div>
        </div>
        <div v-if="runningTime.start">
          <button @click="toggleBillable" class="btn btn-billable float-right" :title="billableTitle">
            <svg-icon name="BillableIcon" v-if="runningTime.billable" />
            <svg-icon name="NotBillableIcon" v-else />
          </button>
        </div>
      </div>
    </div>
    <div class="p-2 mt-2" v-if="errors">
      <p v-for="error in errors" :key="error" class="text-red-500">{{ error }}</p>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import debounce from "lodash/debounce"
import { useToast } from "vue-toastification";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import ProjectSearchInput from "@/components/widgets/ProjectSearchInput.vue";
import DescriptionInput from "@/components/widgets/DescriptionInput.vue";

export default {
  name: "TimeTrack",
  components: { SvgIcon, ProjectSearchInput, DescriptionInput },
  setup() {
    let projectSearchTerm = ref('')
    let selectedProject = ref(null)
    const toast = useToast();
    return {
      selectedProject,
      projectSearchTerm,
      toast
    }
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    time: {
      type: Object,
      required: false,
    },
    projects: {
      type: Object,
      required: false,
    },
  },
  watch: {
    time: {
      handler: function (val) {
        if (val) {
          this.runningTime = val;
          if (this.runningTime && this.runningTime.project_id) {
            this.selectedProject = this.$searchInProjectsById(this.projects, this.runningTime.project_id);
            if (this.selectedProject) {
              this.projectSearchTerm = this.selectedProject.name;
            }
          }
          this.initLiveTimer();
        }
      },
      deep: true
    }
  },
  data() {
    return {
      runningTime: {},
      errors: [],
      projectError: false,
      descriptionError: false,
      descriptionEdited: false,
      startTime: null,
      elapsedTime: null
    };
  },
  computed: {
    searchProjects() {
      if (!this.projects || !this.projectSearchTerm || (this.selectedProject && this.selectedProject.name === this.projectSearchTerm)) {
        return [];
      }

      return this.$searchInProjects(this.projects, this.projectSearchTerm);
    },
    billableTitle() {
      if (this.runningTime.billable) {
        return 'Time Billable. Click to disable.';
      } else {
        return 'Time not Billable. Click to enable.';
      }
    }
  },
  async mounted() {
    this.runningTime = this.time;
    if (this.runningTime) {
      this.initLiveTimer();
      if (this.runningTime.project_id) {
        this.selectedProject = this.$searchInProjectsById(this.projects, this.runningTime.project_id);
        if (this.selectedProject) {
          this.projectSearchTerm = this.selectedProject.name;
        }
      }
    }
  },
  methods: {
    timeEntryUpdated(timeEntry) {
      if (this.runningTime && this.runningTime.id === timeEntry.id && !this.descriptionEdited) {
        this.runningTime = timeEntry;
        this.$emit('update', this.runningTime);
      }
    },
    async continueTimeEntry(timeEntry) {
      if (Object.keys(this.runningTime).length) {
        this.toast.error('Stop your timer first!');
      } else {
        this.runningTime = timeEntry;
        if (timeEntry.project) {
          this.projectSearchTerm = timeEntry.project.name;
          this.selectedProject = timeEntry.project
        }
        await this.submitStart();
      }
    },
    initLiveTimer() {
      this.startTime = new Date(this.runningTime.start).getTime();
      this.updateTimer();

      this.timerInterval = setInterval(this.updateTimer, 1000);
    },
    updateDescription: function (description) {
      if(description) {
        this.runningTime.description = description;
      }
      this.debounceSubmitUpdate()
    },
    debounceSubmitUpdate: debounce(function() {
      this.submitUpdate()
    }, 1000),
    toggleBillable() {
      this.runningTime.billable = !this.runningTime.billable;
      this.submitUpdate();
    },
    selectProject(project) {
      this.runningTime.project_id = project.id;
      this.projectSearchTerm = project.name;
      this.selectedProject = project
      this.runningTime.billable = project.is_billable
      this.submitUpdate();
    },
    updateTimer() {
      this.elapsedTime = this.$getTimeDifference(this.startTime);
    },
    async submitStart() {
      try {
        let data = {};
        if (this.runningTime && this.runningTime.description) {
          data.description = this.runningTime.description;
          
        }
        if (this.selectedProject) {
          data.project_id = this.selectedProject.id;
        }
        const response = await this.$makeRequest(
          "post",
          "/_tasks/api/v1/time-tracker",
          data
        );
        if (response.data) {
          this.runningTime = response.data.data;
          this.initLiveTimer()
          this.$emit('update', this.runningTime);
          this.$emit('start')
        }
      } catch (error) {
        console.error("Failed to start the timer:", error);
      }
    },
    async submitCancel(e) {
      e.preventDefault();
      this.errors = [];
      if (!this.runningTime || !this.runningTime.id) {
        console.error("No running time entry to stop!.");
        return;
      }

      try {
        const response = await this.$makeRequest(
          "post",
          `/_tasks/api/v1/time-tracker/${this.runningTime.id}/cancel`
        );
        if (response.data.error) {
          if (response.data.message.includes('Project')) {
            this.projectError = true;
          } else if (response.data.message.includes('Description')) {
            this.descriptionError = true;
          }
          else {
            this.errors.push(response.data.message);
          }
        } else {
          this.runningTime = {};
          this.selectedProject = null;
          this.projectSearchTerm = '';
          this.$emit('update', this.runningTime);
          this.errors = [];
          this.projectError = false;
          this.descriptionError = false;

        }
      } catch (error) {
        console.error("Failed to cancel the timer:", error);
      }
    },
    async submitStop() {
      this.errors = [];
      if (!this.runningTime || !this.runningTime.id) {
        console.error("No running time entry to stop!.");
        return;
      }

      try {
        const response = await this.$makeRequest(
          "patch",
          `/_tasks/api/v1/time-tracker/${this.runningTime.id}/stop`
        );
        if (response.data.error) {
          if (response.data.message.includes('Project')) {
            this.projectError = true;
          } else if (response.data.message.includes('Description')) {
            this.descriptionError = true;
          }
          else {
            this.errors.push(response.data.message);
          }
        } else {
          this.errors = [];
          this.projectError = false;
          this.descriptionError = false;
          this.runningTime = {};
          this.selectedProject = null;
          this.projectSearchTerm = '';
          this.$emit('update', this.runningTime);
          this.$emit('stop');
        }
      } catch (error) {
        console.error("Failed to stop the timer:", error);
      }
    },
    async submitUpdate() {
      if (!this.runningTime || !this.runningTime.id) {
        console.error("No running time entry to update.");
        return;
      }

      try {
        let payload = {};
        if (this.runningTime.description) {
          payload.description = this.runningTime.description
          this.descriptionError = false;
        }
        if (this.runningTime.billable) {
          payload.billable = this.runningTime.billable
        } else {
          payload.billable = false;
        }
        if (this.selectedProject) {
          payload.project_id = this.selectedProject.id
          this.projectError = false;
        }
        if (Object.keys(payload).length) {
          await this.$makeRequest(
            "put",
            `/_tasks/api/v1/time-tracker/${this.runningTime.id}`,
            payload
          );
          this.$emit('update', this.runningTime);
        }
      } catch (error) {
        console.error("Failed to update the timer:", error);
      }
    },

    async submitDelete() {
      if (!this.runningTime || !this.runningTime.id) {
        console.error("No running time entry to delete.");
        return;
      }

      try {
        await this.$makeRequest(
          "delete",
          `/_tasks/api/v1/time-tracker/${this.runningTime.id}`
        );
        this.runningTime = null;
      } catch (error) {
        console.error("Failed to delete the timer:", error);
      }
    },
    adjustToBrowserTimezone(timestamp) {
      const date = new Date(timestamp);
      const userTimezoneOffset = date.getTimezoneOffset() * 60000;
      const localTime = new Date(date.getTime() - userTimezoneOffset);

      return localTime;
    },
    convertTimeToHours(startTimestampStr) {
      const dateParts = startTimestampStr.split(/[- :]/);
      const dateObject = new Date(
        Date.UTC(
          +dateParts[0],
          dateParts[1] - 1,
          +dateParts[2],
          +dateParts[3],
          +dateParts[4],
          +dateParts[5]
        )
      );
      const startTimestamp = Math.floor(dateObject.getTime() / 1000);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const elapsedSeconds = currentTimestamp - startTimestamp;
      const hours = Math.floor(elapsedSeconds / 3600);
      const minutes = Math.floor((elapsedSeconds % 3600) / 60);
      const seconds = elapsedSeconds % 60;
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    },
  },
};
</script>