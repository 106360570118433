<template>
    <component :is="dynamicSvg" :class="[cssClass, { spin: spin }]" v-if="dynamicSvg"></component>
</template>

<script>
import { markRaw } from 'vue';

export default {
    props: {
        name: {
            type: String,
            required: true
        },
        cssClass: {
            type: String,
            default: ''
        },
        spin: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dynamicSvg: null
        };
    },
    async mounted() {
        try {
            const componentModule = await import(`@/components/icons/svg/${this.name}.vue`);
            this.dynamicSvg = markRaw(componentModule.default);
        } catch (error) {
            console.error('Error loading dynamic SVG:', error);
        }
    }
}
</script>
<style>
.spin {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>