<template>
    <div class="time-entry-item" :class="{ 'time-entry-item-first': first }">
        <div class="grid grid-cols-24 time-entry-title">
            <div class="col-span-4 lg:col-span-2 flex flex-wrap justify-center cursor-pointer time-list-day-column" v-if="!dayMode">
                <div class="flex flex-col items-center time-list-day-container" v-if="first" @click="expandDate()" 
                :title="'Total time: ' + convertSeconds(dayTime)">
                    <span>{{ getDateShortForm(timeEntry.start) }}</span>
                    <div class="flex flex-wrap justify-center time-list-day-count">
                        <div class="flex items-center">{{ this.amount }}</div>
                    </div>
                </div>
            </div>
            <div class="text-dark text-sm time-list-description-column"
                :class="{ 'cursor-pointer': true, 'col-span-14 lg:col-span-16': !dayMode, 'col-span-18 lg:col-span-20': dayMode }" 
                @click="openEditor(timeEntry)">
                <div>
                    <span v-if="timeEntry.description" v-html="parseDescription(timeEntry.description)"></span>
                    <span class="text-muted" v-else>No description...</span>
                </div>
                <div class="time-entry-subtitle">
                    <span v-if="timeEntry.project" class="text-xs mt-1 mb-0">
                        <span :style="'color: ' + timeEntry.project.colour">{{ timeEntry.project.name }}</span> | <span class="client-project-subtitle"
                            v-if="timeEntry.project.client">{{ timeEntry.project.client.name }}</span>
                    </span>
                    <p class="text-muted text-xs mt-1 mb-0" v-else>
                        No project...
                    </p>
                </div>
            </div>
            <div class="flex flex-row justify-end col-span-2 fw-bold">
                <div v-if="timeEntry.billable" class="billable-indicator">
                    <svg-icon name="BillableIndicatorIcon" />
                </div>
            </div>
            <div class="flex flex-col items-end justify-center col-span-4 fw-bold list-time-segment">
                <div class="list-time-container">
                    <div>
                        <span v-if="timeEntry.end" class="">{{ convertSeconds(timeEntry.seconds) }}</span>
                        <span class="float-right" v-else><font-awesome-icon :icon="['fas', 'clock']" /></span>
                    </div>
                    <div class="time-entry-subtitle">
                        <span v-if="timeEntry.end" class="hours">{{ getEntryHours(timeEntry.start, timeEntry.end)
                        }}</span>
                    </div>
                </div>
                <div class="list-continue-container w-full flex justify-end">
                    <button @click="continueTimeEntry(timeEntry)" v-if="timeEntry.end">
                        <svg-icon name="StartIcon" />
                    </button>
                    <div v-else>
                        <span v-if="timeEntry.end" class="">{{ convertSeconds(timeEntry.seconds) }}</span>
                        <span class="float-right" v-else><font-awesome-icon :icon="['fas', 'clock']" /></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SvgIcon from "@/components/icons/SvgIcon.vue";
export default {
    name: "ListTimeEntry",
    components: { SvgIcon },
    props: {
        timeEntry: {
            type: Object,
            required: true,
        },
        dayTime: {
            type: Number,
            required: false,
        },
        date: {
            type: String
        },
        first: {
            type: Boolean,
            default: false
        },
        amount: {
            type: Number,
        },
        dayMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            timeHover: false,
        };
    },
    mounted() {
    },
    computed: {

    },
    methods: {
        continueTimeEntry(timeEntry) {
            this.$emit('continue', timeEntry);
        },
        expandDate() {
            this.$emit('expand', this.date);
        },
        openEditor(timeEntry) {
            this.$emit('edit', timeEntry);
        },
        getEntryHours(start, end) {
            const startHour = new Date(start).getHours().toString().padStart(2, '0');
            const startMinute = new Date(start).getMinutes().toString().padStart(2, '0');
            const endHour = new Date(end).getHours().toString().padStart(2, '0');
            const endMinute = new Date(end).getMinutes().toString().padStart(2, '0');

            return `${startHour}:${startMinute}-${endHour}:${endMinute}`;
        },
        getDateShortForm(dateString) {
            const inputDate = new Date(dateString);

            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);

            if (inputDate.toDateString() === today.toDateString()) {
                return "Today";
            }

            if (inputDate.toDateString() === yesterday.toDateString()) {
                return "Yesterday";
            }

            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            const dayOfWeek = dayNames[inputDate.getUTCDay()];
            const month = monthNames[inputDate.getUTCMonth()];
            const day = inputDate.getUTCDate();

            return `${dayOfWeek}, ${month} ${day}`;
        },
        parseDescription(str) {
            return this.$parseTimeEntryDescription(str);
        },
        convertSeconds(seconds) {
            if (!seconds) {
                return null;
            }
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;
            return `${hours.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
        },
    },
};
</script>
  