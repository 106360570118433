<template>
    <div class="ml-4 mr-4 mt-2 mb-4">
        <div class="flex flex-col bg-gradient-to-r from-purple-600 via-purple-400 to-blue-400 p-4 rounded-xl text-white w-full mx-auto my-auto task-card">
            <div class="grid grid-cols-12 text-xs h-full">
                <div class="flex col-span-7">
                    <div class="flex flex-col h-full">
                        <div class="clickup-task-id">{{ task.remote_id }}</div>
                        <div class="flex flex-grow">
                            <p class="clickup-task-description mt-3 mb-5">{{ parseDescription(timeEntryTask.description, 48) }}</p>
                        </div>
                        <div>
                            <span v-if="task.time_entries" class="clickup-total-time">{{
                                calculateTotalTimeString(task.time_entries)
                            }}
                                <span>({{
                                    task.time_entries.length }})</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex col-span-5">
                    <div class="flex flex-col h-full w-full">
                        <div class="flex flex-grow flex-row self-end card-users-list" v-if="task.users">
                            <div class="flex" v-for="user in task.users" :key="user.id">
                                <img v-if="user.avatar" :src="user.avatar.path" :alt="user.first_name"
                                    :title="user.name + ' ' + user.surname"
                                    :class="`w-8 h-8 rounded-full border-2 float-left mr-1`">
                                <img v-else src="@/assets/images/user.svg" :alt="user.first_name"
                                    :title="user.name + ' ' + user.surname"
                                    :class="`w-8 h-8 rounded-full border-2 float-left mr-1`">
                            </div>
                        </div>
                        <div class="flex justify-end">
                            <img @click="openClickup()" class="clickup-card-logo mr-3 cursor-pointer"
                                src="@/assets/images/clickup.svg" />
                            <div v-if="task.priority" class="clickup-priority mr-3"
                                :title="'Priority: ' + task.priority.toUpperCase()">
                                <svg-icon name="PriorityIcon" :css-class="task.priority.toLowerCase()" />
                            </div>
                            <div v-if="task.status"><button class="clickup-status button cursor-default"
                                    :class="getStatusClass(task.status.toUpperCase())">{{ task.status.toUpperCase()
                                    }}</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SvgIcon from '@/components/icons/SvgIcon.vue';
export default {
    name: "TaskCard",
    setup() {

    },
    components: {
        SvgIcon
    },
    props: {
        timeEntryTask: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            task: {},
            projects: []
        };
    },
    async mounted() {
        const response = await this.$makeRequest('GET', '/_tasks/api/v1/task-details/' + this.timeEntryTask.id)
        this.task = response.data.data;
    },
    computed: {
    },
    methods: {
        parseDescription(str, maxLength = 25) {
            if (str.length > maxLength) {
                return `${str.substr(0, maxLength - 3)}...`
            }

            return str;
        },
        calculateTotalTime(timeEntries) {
            const totalSeconds = timeEntries.reduce((acc, entry) => acc + entry.seconds, 0);

            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },
        calculateTotalTimeString(timeEntries) {
            const totalSeconds = timeEntries.reduce((acc, entry) => acc + entry.seconds, 0);

            // Constants
            const SECONDS_PER_MINUTE = 60;
            const MINUTES_PER_HOUR = 60;
            const HOURS_PER_DAY = 8;
            const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;
            const SECONDS_PER_DAY = SECONDS_PER_HOUR * HOURS_PER_DAY;

            const days = Math.floor(totalSeconds / SECONDS_PER_DAY);
            const hours = Math.floor((totalSeconds % SECONDS_PER_DAY) / SECONDS_PER_HOUR);
            const minutes = Math.floor((totalSeconds % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE);

            let result = [];

            if (days === 1) {
                result.push("1 day");
            } else if (days > 1) {
                result.push(`${days} days`);
            }

            if (hours === 1) {
                result.push("1 hour");
            } else if (hours > 1) {
                result.push(`${hours} hours`);
            }

            if (minutes === 1) {
                result.push("1 minute");
            } else if (minutes > 1) {
                result.push(`${minutes} minutes`);
            }

            return result.join(' ');
        },
        getStatusClass(status) {
            if (status === 'OPEN') {
                return 'task-open'
            }
            if (status === 'IN PROGRESS') {
                return 'task-in-progress'
            }
            if (status === 'COMPLETED') {
                return 'task-completed'
            }
            if (status === 'CLOSED') {
                return 'task-closed'
            }
            if (status === 'CODE REVIEW') {
                return 'task-code-review'
            }
            if (status === 'REVIEW') {
                return 'task-review'
            }
            if (status === 'NEEDS INFO') {
                return 'task-needs-info'
            }
            if (status === 'CLOSED') {
                return 'task-closed'
            }
        },
        openClickup() {
            if (this.task) {
                chrome.tabs.create({
                    url: this.task.url
                });
            }
        }
    },
};
</script>