import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";


export default createStore({
  state: {
    authToken: null,
    user: null,
    officeProcess: false,
    timeStep: null,
    officeView: false
  },

  getters: {
    getOfficeViewEnabled: (state) => {
      return state.officeView;
    },
    getUserTimeStep: (state) => {
      return state.timeStep;
    },
    getAuthToken: (state) => {
      return state.authToken;
    },
    getUser: (state) => {
      return state.user;
    },
    isInOfficeProcess: (state) => {
      return state.officeProcess;
    }
  },

  mutations: {
    SET_OFFICE_VIEW(state, value) {
      state.officeView = value;
    },
    SET_USER_TIMESTEP(state, value) {
      state.timeStep = value;
    },
    SET_OFFICE_PROCESS(state, value) {
      state.officeProcess = value;
    },
    SET_AUTH_TOKEN(state, token) {
      state.authToken = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    CLEAR_AUTH_TOKEN(state) {
      state.authToken = null;
    },
    CLEAR_USER(state) {
      state.user = null;
    },
  },

  actions: {
    setOfficeComViewEnabled({ commit }, value) {
      commit("SET_OFFICE_VIEW", value);
    },
    setUserTimeStep({ commit }, value) {
      commit("SET_USER_TIMESTEP", value);
    },
    setOfficeProcess({ commit }, value) {
      commit("SET_OFFICE_PROCESS", value);
    },
    storeAuthToken({ commit }, token) {
      commit("SET_AUTH_TOKEN", token);
    },
    storeUser({ commit }, user) {
      commit("SET_USER", user);
    },
    logoutUser({ commit }) {
      commit("CLEAR_AUTH_TOKEN");
      commit("CLEAR_USER");
    },
  },
  plugins: [createPersistedState()],
  modules: {},
});
