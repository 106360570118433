<template>
  <div ref="descriptionInputContainer" class="description-input-container  flex flex-col">
    <label>Task</label>
    <input type="text" v-model="localDescription" placeholder="Description..."
           class="form-input p-2 mb-2 border rounded w-full"
           @keyup="getSuggestions()"
           @focus="descriptionFocused()"
           @blur="descriptionBlurred()"
           :class="[classes, { 'red-border': descriptionError }]" />
    <ul class="p-2 w-full project-search-list" v-if="entries.length">
      <li
          class="w-full p-2 border-b cursor-pointer project-search-item"
          v-for="entry in entries"
          :key="entry.id"
          @click="selectSuggestion(entry)"
      >
        <div class="text-dark text-sm time-list-description-column cursor-pointer col-span-14 lg:col-span-16" v-if="entry.project">
          <div><span class="project-search-item-title">{{ entry.description }}</span></div>
          <div class="time-entry-subtitle"><span class="text-xs mt-1 mb-0"><span :style="`color: ${entry.project.colour}`">{{ entry.project.name }}</span> | <span
              class="client-project-subtitle">{{ entry.project.client.name }}</span></span></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import debounce from "lodash/debounce"
export default {
  name: 'DescriptionInput',
  props: {
    runningTime: {
      type: Object,
      required: true
    },
    descriptionError: Boolean,
    selectProject: Function,
    classes: String
  },
  mounted() {
    this.descriptionInputContainer = this.$refs.descriptionInputContainer;
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  data() {
    return {
      localDescription: this.runningTime.description || '',
      descriptionInputContainer: null,
      entries: [],
      loading: false
    };
  },
  watch: {
    'runningTime.description': function (newVal) {
      this.localDescription = newVal;
    },
  },
  methods: {
    descriptionFocused(){
      this.$emit('descriptionFocused');
    },
    descriptionBlurred(){
      this.$emit('descriptionBlurred')
    },
    handleClickOutside(event) {
      if (this.descriptionInputContainer && !this.descriptionInputContainer.contains(event.target)) {
        this.entries = [];
      }
    },
    getSuggestions: debounce(async function() {
      if (this.localDescription && this.localDescription.length > 2) {
        this.loading = true;
        await this.loadApiSuggestions(this.localDescription);
      } else {
        this.entries = [];
      }
      this.$emit('updateDescription', this.localDescription);
    }, 500),
    async loadApiSuggestions(query) {
      try {
        const response = await this.$getSuggestions(query);
        this.entries = response.data.data;
        console.log(this.entries);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    selectSuggestion(entry) {
      this.localDescription = entry.description;
      this.$emit('updateDescription', this.localDescription);
      if (entry.project_id) {
        this.$emit('selectProject', {id: entry.project_id, name: entry.project.name, project: entry.project});
      }
      this.entries = [];
    }
  }
};
</script>