<template>
  <nav class="border-gray-200">
    <div class="flex items-center justify-between mx-auto pl-4 pr-4 pt-6">
      <a class="navbar-brand" :href="getAppUrl()" target="_blank"><img src="@/assets/logo.svg" /></a>
      <a class="navbar-brand-mobile" :href="getAppUrl()" target="_blank"><img src="/img/icons/tt.svg" /></a>
      <div class="" id="navbar-default">
        <ul class="font-medium flex p-1 md:p-0 border border-gray-100 rounded-lg flex-row space-x-8 mt-0 border-0">
          <li class="nav-item">
            <a href="#" @click="openHome()" title="Go to TaskTracker" class="block 
          text-white
          rounded md:bg-transparent 
          p-0 
          dark:text-white">
              <svg-icon name="HomeIcon" :cssClass="tab === 'home' ? 'active' : ''" />
            </a>
          </li>
          <li class="nav-item">
            <a href="#" @click="openRecap()" title="Show Recap" class="block 
              text-white
              rounded md:bg-transparent 
              p-0 
              dark:text-white">
          <svg-icon name="RecapIcon" :cssClass="tab === 'recap' ? 'active' : ''" />
            </a>
          </li>
          <li class="nav-item">
            <a href="#" @click="openStats()" title="Show Stats" class="block 
          text-white
          rounded md:bg-transparent 
          p-0 
          dark:text-white">
          <svg-icon name="StatsIcon" :cssClass="tab === 'stats' ? 'active' : ''" />
            </a>
          </li>
          <li class="nav-item">
            <a href="#" @click="openOptions()" title="Options" class="block
          text-white
          rounded md:bg-transparent 
          p-0 
          dark:text-white">
              <svg-icon name="SettingsIcon" :cssClass="tab === 'options' ? 'active' : ''" />
            </a>
          </li>
          <li class="nav-item">
            <a href="#" @click="refresh()" title="Refresh TaskList" class="block
          text-white
          rounded md:bg-transparent
          p-0
          dark:text-white">
              <svg-icon name="RefreshIcon" :spin="refreshing" />
            </a>
          </li>
          <li class="nav-item">
            <button @click="logout()" title="Log out" class="block 
          text-white
          rounded md:bg-transparent 
          p-0 
          dark:text-white">
            <svg-icon name="LogoutIcon" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import SvgIcon from '@/components/icons/SvgIcon.vue';
export default {
  name: "TimeTrackerNavbar",
  components: { SvgIcon },
  data() {
    return {
      refreshing: false
    };
  },
  props: {
    tab: {
      type: String,
      required: false,
    },
  },
  mounted() {
  },
  computed: {},
  methods: {
    openStats() {
      this.$emit('stats');
    },
    openRecap() {
      this.$emit('recap');
    },
    openHome() {
      this.$emit('home');
    },
    openOptions() {
      this.$emit('options');
    },
    refresh() {
      this.refreshing = true;
      this.$emit('refresh');
      setTimeout(() => {
        this.refreshing = false;
      }, 1000);
    },
    getAppUrl() {
      return process.env.VUE_APP_TASK_TRACKER_URL;
    },
    logout() {
      this.$emit('logout');
    }
  }
};
</script>