<template>
    <div class="time-tracker-footer justify-end items-center" v-if="timerRunning">
        <div class="flex description">
            <span>{{ runningTime.description }}</span>
            <span v-if="currentProject" :style="'margin-left: 5px; color: ' + currentProject.colour"> / {{
                currentProject.name }}</span>
        </div>
        <div class="flex time"><span v-if="elapsedTime !== 'NaN:NaN:NaN'" v-html="elapsedTime"></span></div>
        <div class="flex tracker-button">
            <button v-if="timerRunning" @click="submitStop">
                <svg-icon name="StopIcon" />
            </button>
        </div>
    </div>
</template>
  
<script>
import SvgIcon from '@/components/icons/SvgIcon.vue';
import { useToast } from "vue-toastification";
export default {
    name: "TimeTrackerFooter",
    components: { SvgIcon },
    setup() {
        const toast = useToast();
        return {
            toast
        }
    },
    data() {
        return {
            refreshing: false,
            currentProject: null,
            runningTime: {},
            errors: [],
            projectError: false,
            descriptionError: false,
            startTime: null,
            elapsedTime: null,
            timerInterval: null,
            tooltipVisible: true
        };
    },
    props: {
        tab: {
            type: String,
            required: false,
        },
        time: {
            type: Object,
            required: false,
        },
        timerRunning: {
            type: Boolean,
            required: false,
        },
        projects: {
            type: Object,
            required: false,
        },

    },
    watch: {
        time: {
            handler: function (val) {
                if (val) {
                    this.runningTime = val;
                    this.initLiveTimer();
                }
            },
            deep: true
        }
    },
    mounted() {
        this.runningTime = this.time;
        this.$nextTick(() => {
            if (this.runningTime) {
                this.initLiveTimer();
            }
        });
    },
    beforeDestroy() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    },
    computed: {},
    methods: {
        async submitStop() {
            this.errors = [];
            if (!this.runningTime || !this.runningTime.id) {
                console.error("No running time entry to stop!.");
                return;
            }

            try {
                const response = await this.$makeRequest(
                    "patch",
                    `/_tasks/api/v1/time-tracker/${this.runningTime.id}/stop`
                );
                if (response.data.error) {
                    this.toast.error(response.data.message);
                } else {
                    this.errors = [];
                    this.projectError = false;
                    this.descriptionError = false;
                    this.runningTime = {};
                    this.$emit('update', this.runningTime);
                    this.$emit('stop');
                }
            } catch (error) {
                console.error("Failed to stop the timer:", error);
            }
        },
        initLiveTimer() {
            this.startTime = new Date(this.runningTime.start).getTime();
            if (this.runningTime.project_id) {
                this.currentProject = this.$searchInProjectsById(this.projects, this.runningTime.project_id)
            }
            this.updateTimer();

            this.timerInterval = setInterval(this.updateTimer, 1000);
        },
        updateTimer() {
            this.elapsedTime = this.$getTimeDifference(this.startTime);
        },
    }
};
</script>