<template>
  <div class="m-2 mt-4 ">
    <div class="pl-4 pr-4 flex text-white w-full mx-auto my-auto">
      <div class="flex flex-col">
        <div class="grid grid-cols-2 mt-4 mb-4">
          <div :class="(time.end && mode == 'edit') || mode == 'create' ? 'col-span-1' : 'col-span-2'">
            <label>Start Time:</label>
            <VueDatePicker v-model="time.start" format="dd/MM/yyyy HH:mm" class="input-timeeditor-datepicker"
                           :dark="true" :month-change-on-scroll="false" :teleport="true"
                           :text-input="dateInputOptions"
                           :class="{ 'red-border': errors.dateStartError }">
              <!-- TODO: use slots to style picker https://vue3datepicker.com/slots/content/ -->
            </VueDatePicker>
          </div>
          <div class="col-span-1" v-if="(time.end && mode == 'edit') || mode == 'create'">
            <label>End Time:</label>
            <VueDatePicker v-model="time.end" format="dd/MM/yyyy HH:mm" class="input-timeeditor-datepicker"
                           :dark="true" :month-change-on-scroll="false" :teleport="true"
                           :text-input="dateInputOptions"
                           :class="{ 'red-border': errors.dateEndError }">
              <!-- TODO: use slots to style picker https://vue3datepicker.com/slots/content/ -->
            </VueDatePicker>
          </div>
        </div>

        <div class="grid grid-cols-12 mt-2 items-center">
          <div class="col-span-7">
            <DescriptionInput
                :runningTime="time"
                :descriptionError="errors.descriptionError"
                @updateDescription="updateDescription"
                classes="input-timeeditor"
                @selectProject="selectProject"
            />
          </div>
          <div class="col-span-5">
            <div class="grid grid-cols-12 items-center edit-buttons-container" v-if="!deleteConfirmation">
              <div class="flex col-span-12 justify-end content-center flex-wrap" v-if="mode == 'edit'">
                <button class="flex flex-row button-task-delete" @click="confirmDelete()">
                  <svg-icon name="TrashIcon"/>
                  <span>Delete</span>
                </button>
              </div>
            </div>

          </div>
        </div>

        <div class="grid grid-cols-12 items-center">
          <div class="col-span-7">
            <ProjectSearchInput
                :modelValue="projectSearchTerm"
                @update:modelValue="projectSearchTerm = $event"
                :projectError="projectError"
                :searchProjects="searchProjects"
                classes="input-timeeditor"
                @selectProject="selectProject"
            />
          </div>
          <div class="col-span-5">
            <div class="grid grid-cols-12 confirm-button-container" v-if="!deleteConfirmation">
              <div class="flex justify-end col-span-12">
                <button type="button" @click="toggleBillable()">
                  <svg-icon name="BillableIcon" css-class="edit-billable-button" v-if="time.billable"/>
                  <svg-icon name="NotBillableIcon" css-class="edit-notbillable-button" v-else/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <task-card v-if="time.task" :timeEntryTask="time.task"></task-card>
    <div class="grid grid-cols-2 confirm-button-container" v-if="deleteConfirmation">
      <div class="flex col-span-1 justify-end mr-2">
        <button type="button" @click="cancelDelete()"
                class="button-task-tracker" :class="{
                                        'cursor-not-allowed': loading
                                    }" :disabled="loading"><svg-icon name="ArrowLeftIcon" css-class="edit-confirm-icon"/> Cancel
        </button>
      </div>
      <div class="flex col-span-1 ml-2">
        <button type="button" @click="submitDelete()"
                class="button-task-tracker edit-delete-button" :class="{
                                        'cursor-not-allowed': loading
                                    }" :disabled="loading"><svg-icon name="CheckIcon" css-class="edit-confirm-icon"/> I am sure
        </button>
      </div>
    </div>


    <div class="grid grid-cols-2 confirm-button-container" v-if="!deleteConfirmation">
      <div class="flex col-span-1 justify-end mr-2">
        <button type="button" @click="submitCancel()" class="button-task-tracker edit-cancel-button" :class="{
                                        'cursor-not-allowed': loading
                                    }" :disabled="loading">
          <svg-icon name="ArrowLeftIcon" css-class="edit-confirm-icon"/> Back
        </button>

      </div>
      <div class="flex col-span-1 ml-2">
        <button type="button" @click="submitUpdate()" class="button-task-tracker edit-confirm-button" :class="{
                                        'cursor-not-allowed': loading
                                    }" :disabled="loading" v-if="mode == 'edit'"
                title="Update Time Entry">
          <svg-icon name="CheckIcon" css-class="edit-confirm-icon"/> Confirm
        </button>
        <button type="button" @click="submitCreate()" class="button-task-tracker edit-confirm-button" :class="{
                                        'cursor-not-allowed': loading
                                    }" :disabled="loading" v-if="mode == 'create'"
                title="Create Time Entry">
          <svg-icon name="CheckIcon" css-class="edit-confirm-icon"/> Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import moment from 'moment';
import TaskCard from "@/components/editor/TaskCard.vue";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import {useToast} from "vue-toastification";
import '@vuepic/vue-datepicker/dist/main.css';
import ProjectSearchInput from "@/components/widgets/ProjectSearchInput.vue";
import DescriptionInput from "@/components/widgets/DescriptionInput.vue";

export default {
  name: "TimeEdit",
  components: {DescriptionInput, ProjectSearchInput, TaskCard, SvgIcon, VueDatePicker},
  setup() {
    let projectSearchTerm = ref('')
    let selectedProject = ref(null)
    const toast = useToast();
    return {
      selectedProject,
      projectSearchTerm,
      toast
    }
  },
  props: {
    mode: {
      type: String,
      default: 'edit'
    },
    timeEntry: {
      type: Object,
      default: null
    },
    return: {
      type: String,
      default: 'home'
    },
    returnParams: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      time: this.timeEntry,
      deleteConfirmation: false,
      runningEntryUpdate: false,
      errors: {},
      projects: [],
      dateInputOptions: {
        enterSubmit: true,
        tabSubmit: true,
        openMenu: true,
      }
    };
  },
  mounted() {
    if (this.time.id || this.time.project) {
      this.initializeTimeEntry();
    }
    this.loadProjects();
  },
  computed: {
    searchProjects() {
      if (!this.projects || !this.projectSearchTerm || (this.selectedProject && this.selectedProject.name === this.projectSearchTerm)) {
        return [];
      }

      return this.$searchInProjects(this.projects, this.projectSearchTerm);
    },
  },
  methods: {
    initializeTimeEntry() {
      if (this.time.billable) {
        this.time.billable = true;
      } else {
        this.time.billable = false;
      }
      if (this.time.project) {
        this.projectSearchTerm = this.time.project.name
        this.selectedProject = this.time.project
      }
      this.time.start = this.utcToLocalDate(this.time.start);
      if (this.time.end) {
        this.time.end = this.utcToLocalDate(this.time.end);
      } else {
        this.liveEntryUpdate = true;
      }
    },
    async loadProjects() {
      try {
        const response = await this.$makeRequest(
            "get",
            "/_tasks/api/v1/clients-projects"
        );
        this.projects = response.data.data;
      } catch (error) {
        console.error("Failed to load projects:", error);
        if (error.response && error.response.data) {
          this.toast.error(error.response.data.message);
        }
      }
    },
    toggleBillable() {
      this.time.billable = !this.time.billable;
    },
    async submitCancel() {
      this.$emit('cancel', {page: this.return, params: this.returnParams});
    },
    async submitCreate() {
      this.loading = true;
      try {
        const data = this.$constructPayload(this.time, this.selectedProject, this.liveEntryUpdate);
        this.errors = data.errors;
        const payload = data.payload
        if (data.hasErrors) {
          this.loading = false;
          return;
        }

        if (Object.keys(payload).length) {
          await this.$makeRequest("post", `/_tasks/api/v1/time-tracker`, payload);
          this.$emit('update', {page: this.return, params: this.returnParams});
          this.errors = {};
          this.loading = false;
        }
      } catch (error) {
        console.error("Failed to create the timer:", error);
        this.loading = false;
        if (error.response && error.response.data) {
          this.toast.error(error.response.data.message);
        }
      }
    },
    updateDescription(description) {
      this.time.description = description;
    },
    async submitUpdate() {
      if (!this.time || !this.time.id) {
        console.error("No time entry to update.");
        return;
      }

      this.loading = true;
      try {
        const data = this.$constructPayload(this.time, this.selectedProject, this.liveEntryUpdate);
        const payload = data.payload
        this.errors = data.errors;
        if (data.hasErrors) {
          this.loading = false;
          return;
        }

        if (Object.keys(payload).length) {
          await this.$makeRequest("put", `/_tasks/api/v1/time-tracker/${this.time.id}`, payload);
          this.$emit('update', {page: this.return, params: this.returnParams, timeEntry: this.time});
          this.loading = false;
        }
      } catch (error) {
        console.error("Failed to update the timer:", error);
        if (error.response && error.response.data) {
          this.toast.error(error.response.data.message);
        }
      }
    },
    async cancelDelete() {
      this.deleteConfirmation = false;
    },
    async confirmDelete() {
      this.deleteConfirmation = true;
    },
    async submitDelete() {
      if (!this.time || !this.time.id) {
        console.error("No running time entry to delete.");
        return;
      }
      this.loading = true;
      try {
        await this.$makeRequest(
            "delete",
            `/_tasks/api/v1/time-tracker/${this.time.id}`
        );
        this.time = null;
        this.$emit('update', {page: this.return, params: this.returnParams});
        this.loading = false;
      } catch (error) {
        console.error("Failed to delete the timer:", error);
      }
    },
    selectProject(project) {
      this.time.project_id = project.id
      this.projectSearchTerm = project.name
      this.selectedProject = project
      this.time.billable = project.is_billable
    },
    localDateToUTC(localDate) {
      return moment(localDate).utc().format('YYYY-MM-DD HH:mm:ss');
    },
    utcToLocalDate(utcDateStr) {
      return new Date(utcDateStr);
    },

  },
};
</script>